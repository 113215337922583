import React, { Component } from "react";
import moment from "moment";

export class OrderExeptionToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalExpQty: 0,
      totalExpShipped: 0,
      exceptionDataList: [],
      divisionNum: "",
      divisionName: "",
      supplierNumber: "",
      supplierName: "",
      storeNumber: "",
      orderDate: "",
      deliveryDate: "",
    };
  }

  componentDidMount() {
    let divisionNum = sessionStorage.getItem("divisionNumber");
    let divisionName = sessionStorage.getItem("divisionName");
    let supplierNumber = sessionStorage.getItem("supplierStoreNumber");
    let supplierName = sessionStorage.getItem("supplierName");
    let storeNumber = sessionStorage.getItem("storeNumber");
    let deliveryDate = sessionStorage.getItem("deliveryDate");
    var date = moment(deliveryDate).format("MMM DD YYYY");

    let orderDateForPrint = [
      ...new Set(
        sessionStorage
          .getItem("orderDate")
          .split(",")
          .map((item) => item.trim())
      ),
    ];
    let orderDate = orderDateForPrint.toString();

    this.setState({
      divisionNum: divisionNum,
      divisionName: divisionName,
      supplierNumber: supplierNumber,
      supplierName: supplierName,
      storeNumber: storeNumber,
      orderDate: orderDate,
      deliveryDate: date,
    });
    this.getStoreRouteData();
  }

  /**
   * @description     Function to get the store order exeption details
   */
  getStoreRouteData = () => {
    let totalExpQty = 0;
    let totalExpShipped = 0;
    let orderExceptionData = this.props.data;

    if (orderExceptionData !== undefined) {
      if (
        orderExceptionData !== null &&
        orderExceptionData.orderExceptionList !== null &&
        orderExceptionData.orderExceptionList !== undefined &&
        orderExceptionData.orderExceptionList.length > 0
      ) {
        for (let i = 0; i < orderExceptionData.orderExceptionList.length; i++) {
          totalExpQty =
            totalExpQty +
            parseInt(
              orderExceptionData.orderExceptionList[i].quantityOrdered !== null
                ? orderExceptionData.orderExceptionList[i].quantityOrdered
                : 0
            );
        }
        for (let i = 0; i < orderExceptionData.orderExceptionList.length; i++) {
          totalExpShipped =
            totalExpShipped +
            parseInt(
              orderExceptionData.orderExceptionList[i].quantityNotShipped !==
                null
                ? orderExceptionData.orderExceptionList[i].quantityNotShipped
                : 0
            );
        }

        if (isNaN(totalExpQty)) {
          totalExpQty = 0;
        }

        if (isNaN(totalExpShipped)) {
          totalExpShipped = 0;
        }
        this.setState({
          exceptionDataList: orderExceptionData.orderExceptionList,
          totalExpQty: totalExpQty,
          totalExpShipped: totalExpShipped,
        });
      } else {
        this.setState({
          exceptionDataList: [],
          totalExpQty: totalExpQty,
          totalExpShipped: totalExpShipped,
        });
      }
    } else {
      this.setState({
        exceptionDataList: [],
        totalExpQty: totalExpQty,
        totalExpShipped: totalExpShipped,
      });
    }
  };

  render() {
    return (
      <div>
        <div class="row">
          <div class="container-fluid">
            <h3 class="text-center" data-testid="printHeading">
              Order Exceptions
            </h3>
          </div>
          <div class="col-sm-3">
            <ul class="list-unstyled">
              <li data-testid="nameDivisionHeadPrint">
                Division:
                <span class="uppercase">
                  <b>
                    {" "}
                    {this.state.divisionNum} - {this.state.divisionName}
                  </b>
                </span>
              </li>
              <li data-testid="nameStoreHeadPrint">
                Store:
                <span class="uppercase">
                  <b> {this.state.storeNumber} </b>{" "}
                </span>
              </li>
              <li data-testid="nameSupplierHeadPrint">
                Supplier:
                <span class="uppercase">
                  <b>
                    {" "}
                    {this.state.supplierNumber}-
                    {this.state.supplierName &&
                      this.state.supplierName.trim().replace(/\0/g, "")}{" "}
                  </b>
                </span>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <ul class="list-unstyled">
              <li data-testid="nameOrderDateHeadPrint">
                Order Date:
                <span class="uppercase">
                  <b> {this.state.orderDate} </b>
                </span>
              </li>
              <li data-testid="nameDeliveryHeadPrint">
                Delivery Date:
                <span class="uppercase">
                  <b>
                    {this.state.deliveryDate.slice(3, 6)}-
                    {this.state.deliveryDate.slice(0, 3)}-
                    {this.state.deliveryDate.slice(9, 11)}
                  </b>
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>

        <div class="tableRow">
          <table class="table-prop">
            <thead>
              <tr>
                <th scope="col" class="text-center">
                  Inv <br /> Nbr
                </th>
                <th scope="col" class="text-center">
                  CIC
                </th>
                <th scope="col" class="text-center">
                  DSD UPC
                </th>
                <th scope="col" class="text-center">
                  New <br /> 
                  Product
                </th>
                <th scope="col" class="text-center br-width">
                  BR
                  <br />
                  Item #
                </th>
                <th scope="col" class="text-center">
                  Item Description
                </th>
                <th scope="col" class="text-center">
                  Order
                  <br />
                  Qty
                </th>
                <th scope="col" style={{ width: "10%" }} class="text-center">
                  Ship Qty <br/>
                  Adjust +/-
                </th>
                <th scope="col" class="text-center">
                  Discrepancy Reason
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.exceptionDataList.map((value) => {
                return this.renderOrderExceptionList(value);
              })}
            </tbody>
            <tr>
              <td colSpan="5" class="text-left">
                Total
              </td>
              <td class="text-center">{this.state.totalExpQty}</td>
              <td class="text-center">{this.state.totalExpShipped}</td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    );
  }

  renderOrderExceptionList = (value) => {
    try {
      return (
        <tr
          key={value.invNbr}
          style={{
            borderTop: value.orderedCICLines === 1 ? "2px solid black" : "",
          }}
        >
          <td class="text-center">
            {value.invoiceNumber === "0" ? " " : value.invoiceNumber}
          </td>
          <td class="text-center">
            {" "}
            {value.sub1TypCD !== null && value.sub1TypCD !== " "
              ? "*" + value.corpItemCode
              : value.corpItemCode}
          </td>
          <td class="text-center">{value.upcItem}</td>
          <td class="text-center">{value.newProduct}</td>
          <td class="text-center">{value.itemID}</td>
          <td class="text-left">{value.itemDesc}</td>
          <td class="text-center">
            {value.quantityOrdered ? Math.trunc(value.quantityOrdered) : 0}
          </td>
          <td class="text-center">
            {value.quantityNotShipped
              ? Math.trunc(value.quantityNotShipped)
              : 0}
          </td>
          <td class="text-left">
            {value.altCorpItemCode > 0
              ? value.discrepencyReason + ":" + value.altCorpItemCode
              : value.discrepencyReason}
          </td>
        </tr>
      );
    } catch (err) {}
  };
}
export default OrderExeptionToPrint;
