import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "../assets/css/storeDetail.module.css";
import { Header } from "./Header";
import moment from "moment";
import { FetchWrapper } from "../util/FetchWrapper";
import { FetchWrapperPost } from "../util/FetchWrapperPost";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Tooltip from "@material-ui/core/Tooltip";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const down = require("../assets/images/down.png");
const backButton = require("../assets/images/back-button.png");
const deliveredImg = require("../assets/images/delivered.svg");
const transitImg = require("../assets/images/transit.svg");
const holdImg = require("../assets/images/hold.svg");
const submittedImg = require("../assets/images/orderreceived.png");
const cancelledImg = require("../assets/images/cancelled.svg");
const emptyImg = require("../assets/images/emptyImg.png");

export class StoreDetail extends Component {
  constructor(props) {
    super(props);
    this.storeWrapperRef = React.createRef();
    this.dcWrapperRef = React.createRef();
    this.visibilityDropDownRef = React.createRef();
    this.visibilityTriggerRef = React.createRef();

    this.state = {
      status_selected: [],
      status_selectedDC: [],
      dataListDC: [],
      dataListDCAll: [],
      dataListStore: [],
      dataListStoreAll: [],
      prevSession: [],
      dcPrevSession: [],
      tabSelected: "",
      dropDownMenuStore: false,
      dropDownMenuDC: false,
      currentStoreMenu: "All",
      currentDCMenu: "All",
      divisionName: "",
      divisionNumber: "",
      storeNumber: "",
      distributionNumber: "",
      distributionName: "",
      supplierNumber: "",
      orderTypeCode: "",
      orderDate: "",
      orderDateDB: "",
      storeNoDataMsg: false,
      dcNoDataMsg: false,
      loaderStore: "",
      loaderDc: "",
    };
  }

  componentDidMount() {
    sessionStorage.setItem("home", false);

    let tabSelected = sessionStorage.getItem("tabSelected");
    let storeStatus = sessionStorage.getItem("storeStatus") !== '' ? 
                        sessionStorage.getItem("storeStatus") : 'All';
    let dcStatus = sessionStorage.getItem("dcStatus");
    let divisionNum = sessionStorage.getItem("divisionNumber");
    let divisionName = sessionStorage.getItem("divisionName");
    let storeNum = sessionStorage.getItem("storeNumber");
    let orderDate = sessionStorage.getItem("orderDateFilter");
    let orderDateDB = sessionStorage.getItem("orderDateDB");
    let supplierNum = sessionStorage.getItem("supplierNumber");
    let userId = sessionStorage.getItem("userId");
    let orderTypeCode = sessionStorage.getItem("code");
    let type = sessionStorage.getItem("type");
    let distributionNumber = sessionStorage.getItem("distributionNumber");
    let distributionName = sessionStorage.getItem("distributionName");
    sessionStorage.setItem("showRoute", "1");
    sessionStorage.setItem("showException", "1");
    sessionStorage.setItem("showLocateTruck", false);
    this.setState({
      tabSelected: tabSelected,
      currentStoreMenu: storeStatus,
      currentDCMenu: dcStatus,
      divisionName: divisionName,
      divisionNumber: divisionNum,
      storeNumber: storeNum,
      supplierNumber: supplierNum,
      orderDate: orderDate,
      orderDateDB: orderDateDB,
      orderTypeCode: orderTypeCode,
      type: type,
      distributionName: distributionName,
      distributionNumber: distributionNumber,
      alert: "",
      open: false,
      prevSession: [storeStatus],
      dcPrevSession: [dcStatus]
    });

    setTimeout(() => {
      if (tabSelected === "store") {
        if((orderTypeCode !== null && orderTypeCode !== "" && orderTypeCode !== "All") || (orderDate !== null && orderDate !== "" && orderDate !== "All")){
          
          if (storeStatus !== "All") {
            if (storeStatus === "Order Submitted") {
              storeStatus = "received";
            } else if (storeStatus === "In Transit") {
              storeStatus = "intransit";
            } else if (storeStatus === "Delivered") {
              storeStatus = "delivered";
            } else if (storeStatus === "Cancelled") {
              storeStatus = "cancelled";
            } else if (storeStatus === "On Hold") {
              storeStatus = "onhold";
            } else if(storeStatus === null){
              storeStatus = "";
            }
          }
          if(storeStatus === "All"){
            storeStatus = "";
          }
          this.getStoreDetailSupplierStatusOrderDateOrderTypeList(
            storeNum,
            divisionNum,
            supplierNum,
            storeStatus,
            orderDateDB,
            orderTypeCode
          );
        }
        else {
        if (supplierNum === "" && storeStatus === "All") {
          this.getStoreDetailList(storeNum, divisionNum);
        } else if (supplierNum !== "" && storeStatus === "All") {
          this.getStoreDetailSupplierList(storeNum, divisionNum, supplierNum);
        } else if (supplierNum === "" && storeStatus !== "All") {
          if (storeStatus === "Order Submitted") {
            storeStatus = "received";
          } else if (storeStatus === "In Transit") {
            storeStatus = "intransit";
          } else if (storeStatus === "Delivered") {
            storeStatus = "delivered";
          } else if (storeStatus === "Cancelled") {
            storeStatus = "cancelled";
          } else if (storeStatus === "On Hold") {
            storeStatus = "onhold";
          } else if(storeStatus === null){
            storeStatus = "All";
          }
          this.getStoreDetailStatusList(storeNum, divisionNum, storeStatus);
        } else if (supplierNum !== "" && storeStatus !== "All") {
          if (storeStatus === "Order Submitted") {
            storeStatus = "received";
          } else if (storeStatus === "In Transit") {
            storeStatus = "intransit";
          } else if (storeStatus === "Delivered") {
            storeStatus = "delivered";
          } else if (storeStatus === "Cancelled") {
            storeStatus = "cancelled";
          } else if (storeStatus === "On Hold") {
            storeStatus = "onhold";
          } else if(storeStatus === null){
            storeStatus = "All";
          }
          this.getStoreDetailSupplierStatusList(
            storeNum,
            divisionNum,
            supplierNum,
            storeStatus
          );
        }
      }

      if (dcStatus === "In Transit") {
        dcStatus = "intransit";
      } else if (dcStatus === "Delivered") {
        dcStatus = "delivered";
      }
      this.setUserSearchCriteria(
        userId,
        storeNum,
        divisionNum,
        supplierNum,
        storeStatus, 
        distributionNumber, 
        dcStatus,
        'S'
      );
      } else if (tabSelected === "distribution") {
        if (dcStatus === "In Transit") {
          dcStatus = "intransit";
        } else if (dcStatus === "Delivered") {
          dcStatus = "delivered";
        }
        if (storeStatus === "Order Submitted") {
          storeStatus = "received";
        } else if (storeStatus === "In Transit") {
          storeStatus = "intransit";
        } else if (storeStatus === "Delivered") {
          storeStatus = "delivered";
        } else if (storeStatus === "Cancelled") {
          storeStatus = "cancelled";
        } else if (storeStatus === "On Hold") {
          storeStatus = "onhold";
        } else if(storeStatus === null){
          storeStatus = "All";
        }
        if (dcStatus === "All" || dcStatus === "") {
          this.getDCDetailList(distributionNumber);
          this.setUserSearchCriteria(
            userId,
            storeNum,
            divisionNum,
            supplierNum,
            storeStatus, 
            distributionNumber, 
            dcStatus,
            'D');
        } else {
          // if (dcStatus === "In Transit") {
          //   dcStatus = "intransit";
          // } else if (dcStatus === "Delivered") {
          //   dcStatus = "delivered";
          // }
          this.getDCDetailStatusList(distributionNumber, dcStatus);
          this.setUserSearchCriteria(
            userId,
            storeNum,
            divisionNum,
            supplierNum,
            storeStatus,
            distributionNumber,
            dcStatus,
            'D');
        }
        
      }
    }, 100);
  }

  /**
   * @description     Function to get the Store data list
   */
  getStoreDetailList = async (storeNum, divisionNum) => {
    this.setState({
      loaderStore: 1,
      storeNoDataMsg: false,
      dataListStore: [],
    });

    FetchWrapper("/store/" + storeNum + "/division/" + divisionNum, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;

        if (data !== undefined) {
          if (
            data.storeOrderGroupList !== null &&
            data.storeOrderGroupList.length !== 0
          ) {
            this.setState({
              dataListStore: data.storeOrderGroupList,
              dataListStoreAll: [...data.storeOrderGroupList],
              storeNoDataMsg: false,
              loaderStore: "",
            });
          } else {
            this.setState({
              dataListStore: [],
              dataListStoreAll: [],
              storeNoDataMsg: true,
              loaderStore: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
   * @description     Function to get the Store data list if supplier selected
   */
  getStoreDetailSupplierList = async (storeNum, divisionNum, supplierNum) => {
    this.setState({
      loaderStore: 1,
      storeNoDataMsg: false,
      dataListStore: [],
    });

    FetchWrapper(
      "/store/" +
      storeNum +
      "/division/" +
      divisionNum +
      "/supplierid/" +
      supplierNum,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          if (
            data.storeOrderGroupList !== null &&
            data.storeOrderGroupList.length !== 0
          ) {
            this.setState({
              dataListStore: data.storeOrderGroupList,
              dataListStoreAll: [...data.storeOrderGroupList],
              storeNoDataMsg: false,
              loaderStore: "",
            });
          } else {
            this.setState({
              dataListStore: [],
              dataListStoreAll: [],
              storeNoDataMsg: true,
              loaderStore: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
   * @description     Function to get the Store data list if status selected
   */
  getStoreDetailStatusList = async (storeNum, divisionNum, storeStatus) => {
    this.setState({
      loaderStore: 1,
      storeNoDataMsg: false,
      dataListStore: [],
    });

    FetchWrapper(
      "/store/" +
      storeNum +
      "/division/" +
      divisionNum +
      "/orderStatus/" +
      storeStatus,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          if (
            data.storeOrderGroupList !== null &&
            data.storeOrderGroupList.length !== 0
          ) {
            this.setState({
              dataListStore: data.storeOrderGroupList,
              dataListStoreAll: [...data.storeOrderGroupList],
              storeNoDataMsg: false,
              loaderStore: "",
            });
          } else {
            this.setState({
              dataListStore: [],
              dataListStoreAll: [],
              storeNoDataMsg: true,
              loaderStore: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
   * @description     Function to get the Store data list if supplier and status selected
   */
  getStoreDetailSupplierStatusList = async (
    storeNum,
    divisionNum,
    supplierNum,
    storeStatus
  ) => {
    this.setState({
      loaderStore: 1,
      storeNoDataMsg: false,
      dataListStore: [],
    });

    FetchWrapper(
      "/store/" +
      storeNum +
      "/division/" +
      divisionNum +
      "/supplierid/" +
      supplierNum +
      "/orderStatus/" +
      storeStatus,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          if (
            data.storeOrderGroupList !== null &&
            data.storeOrderGroupList.length !== 0
          ) {
            this.setState({
              dataListStore: data.storeOrderGroupList,
              dataListStoreAll: [...data.storeOrderGroupList],
              storeNoDataMsg: false,
              loaderStore: "",
            });
          } else {
            this.setState({
              dataListStore: [],
              dataListStoreAll: [],
              storeNoDataMsg: true,
              loaderStore: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
   * @description     Function to get the Store data list if supplier, orderstatus, orderdate and ordertypecode selected
   */
   getStoreDetailSupplierStatusOrderDateOrderTypeList = async (storeNum, divisionNum, supplierNum, orderStatus, orderDate, orderTypeCode) => {
    this.setState({
      loaderStore: 1,
      storeNoDataMsg: false,
      dataListStore: [],
    });
    FetchWrapper(
	  "/store/filterStoreOrders?" +
      "storeNumber=" +
      storeNum +
      "&division=" +
      divisionNum +
      "&supplierid=" +
      supplierNum +
	  "&orderStatus=" +
      orderStatus +
	  "&orderDt=" +
      orderDate +
	  "&orderTypeCode=" +
      orderTypeCode,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          if (
            data.storeOrderGroupList !== null &&
            data.storeOrderGroupList.length !== 0
          ) {
            this.setState({
              dataListStore: data.storeOrderGroupList,
              dataListStoreAll: [...data.storeOrderGroupList],
              storeNoDataMsg: false,
              loaderStore: "",
            });
          } else {
            this.setState({
              dataListStore: [],
              dataListStoreAll: [],
              storeNoDataMsg: true,
              loaderStore: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
  * @description     Function to update the latest store search criteria
  */
   setUserSearchCriteria = async (
    userId,
    storeNum,
    divisionNum,
    supplierNum,
    storeStatus,
    dcId,
    dcOrderStatus,
    lastSearchPane
  ) => {
    try {
      if(supplierNum === '') supplierNum = null;
      if(dcId === '') dcId = null;
      if(dcOrderStatus === '' || dcOrderStatus === 'All') dcOrderStatus = null;
      if(storeStatus === '' || storeStatus === 'All') storeStatus = null;
      const body = JSON.stringify({
        userId: userId,
        divisionId: divisionNum,
        storeId: storeNum,
        supplierId: supplierNum,
        orderStatus: storeStatus,
        dcId: dcId,
        dcOrderStatus: dcOrderStatus,
        lastSearchPane: lastSearchPane,
        dwCreateTs: null,
        dwCreateUserId: null,
        dwLastUpdateTs: null,
        dwLastUpdateUserId: null
      })
      FetchWrapperPost("/user/usersearchinfo", "POST", body);
    }
    catch (error) {
    }
  };

  /**
  * @description     Function to update the latest store search criteria
  */
  /* setStoreUserSearchCriteria = async (
    userId,
    storeNum,
    divisionNum,
    supplierNum,
    storeStatus
  ) => {
    try {
      const body = JSON.stringify({
        userId: userId,
        divisionId: divisionNum,
        storeId: storeNum,
        supplierId: supplierNum,
        orderStatus: storeStatus,
        dcId: null,
        dcOrderStatus: null,
        lastSearchPane: 'S',
        dwCreateTs: null,
        dwCreateUserId: null,
        dwLastUpdateTs: null,
        dwLastUpdateUserId: null
      })
      FetchWrapperPost("/user/usersearchinfo", "POST", body);
    }
    catch (error) {
    }
  }; */

  /**
   * @description     Function to get the DC data list
   */
  getDCDetailList = async (distributionNumber) => {
    this.setState({
      loaderDc: 1,
      dataListDC: [],
      dcNoDataMsg: false,
    });

    FetchWrapper("/dc/" + distributionNumber, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          data.dcOrderResponseList.sort(
            (a, b) =>
              new Date(b.estimatedDelivery) - new Date(a.estimatedDelivery)
          );

          if (
            data.dcOrderResponseList !== null &&
            data.dcOrderResponseList.length !== 0
          ) {
            this.setState({
              dataListDC: data.dcOrderResponseList,
              dataListDCAll: [...data.dcOrderResponseList],
              dcNoDataMsg: false,
              loaderDc: "",
            });
          } else {
            this.setState({
              dataListDC: [],
              dataListDCAll: [],
              dcNoDataMsg: true,
              loaderDc: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderDc: "",
        });
      });
  };

  /**
   * @description     Function to get the DC data list if status selected
   */
  getDCDetailStatusList = async (distributionNumber, dcStatus) => {
    this.setState({
      loaderDc: 1,
      dataListDC: [],
      dcNoDataMsg: false,
    });

    FetchWrapper(
      "/dc/" + distributionNumber + "/orderStatus/" + dcStatus,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data !== undefined) {
          data.dcOrderResponseList.sort(
            (a, b) =>
              new Date(b.estimatedDelivery) - new Date(a.estimatedDelivery)
          );
          if (
            data.dcOrderResponseList !== null &&
            data.dcOrderResponseList.length !== 0
          ) {
            this.setState({
              dataListDC: data.dcOrderResponseList,
              dataListDCAll: [...data.dcOrderResponseList],
              dcNoDataMsg: false,
              loaderDc: "",
            });
          } else {
            this.setState({
              dataListDC: [],
              dataListDCAll: [],
              dcNoDataMsg: true,
              loaderDc: "",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: 1,
          open: true,
          loaderDc: "",
        });
      });
  };

  /**
 * @description     Function to update the DC search criteria
 */
  /* setDCUserSearchCriteria = async (userId, distributionNumber, dcStatus) => {
    try {
      const body = JSON.stringify({
        userId: userId,
        divisionId: null,
        storeId: null,
        supplierId: null,
        orderStatus: null,
        dcId: distributionNumber,
        dcOrderStatus: dcStatus !== 'All' && dcStatus !== ''  ? dcStatus : null,
        lastSearchPane: 'D',
        dwCreateTs: null,
        dwCreateUserId: null,
        dwLastUpdateTs: null,
        dwLastUpdateUserId: null
      })
      FetchWrapperPost("/user/usersearchinfo", "POST", body);
    }
    catch (error) {
    }
  }; */

  /**
   * @description     Function to navigate to home page
   */
  homeClick = () => {
    this.props.history.push("/");
  };

  refreshClick = () => {
    window.location.reload(); 
  };

  /**
   * @description     Function to navigate to home page for new selection
   */
  tryAgain = () => {
    if(this.state.tabSelected == "store"){
    let lastStatus = this.state.prevSession;
    let lastSession = lastStatus.pop();
    this.dropDownStoreMenuClicked(lastStatus[lastStatus.length-1], "undo");
    if(lastStatus.length == 0) this.props.history.push("/");
    }else if(this.state.tabSelected == "distribution"){
    let lastStatus = this.state.dcPrevSession;
    let lastSession = lastStatus.pop();
    this.dropDownDCMenuClicked(lastStatus[lastStatus.length-1], "undo");
    if(lastStatus.length == 0) this.props.history.push("/");
    }  
  };
  /**
   * @description     Function to view the store dropdown selection
   */
  dropDownOpenStore = () => {
    this.setState({
      dropDownMenuStore: !this.state.dropDownMenuStore,
    });
    if (this.state.dropDownMenuStore === false) {
      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomRightRadius = "0px";
      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomLeftRadius = "0px";
    } else {
      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomRightRadius = "17px";
      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomLeftRadius = "17px";
    }
    setTimeout(() => {
      try {
        document.getElementById(
          "store " + this.state.currentStoreMenu
        ).style.backgroundColor = "rgba(222, 245, 255, 0.8)";
        document.getElementById(
          "store " + this.state.currentStoreMenu
        ).style.color = "#4F4F4F";
        document.addEventListener("click", this.handleClickOutsideStore);
      } catch (error) { }
    }, 100);
  };

  handleClickOutsideStore = (event) => {
    if (
      this.storeWrapperRef &&
      this.storeWrapperRef.current !== null &&
      !this.storeWrapperRef.current.contains(event.target)
    ) {
      document.removeEventListener("click", this.handleClickOutsideStore);

      this.setState({
        dropDownMenuStore: false,
      });

      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomRightRadius = "17px";
      document.getElementById(
        "buttonStoreDropDown"
      ).style.borderBottomLeftRadius = "17px";

      setTimeout(() => {
        try {
          document.getElementById(
            "store " + this.state.currentStoreMenu
          ).style.backgroundColor = "rgba(222, 245, 255, 0.8)";
          document.getElementById(
            "store " + this.state.currentStoreMenu
          ).style.color = "#4F4F4F";
        } catch (error) { }
      }, 100);
    }
  };

  /**
   * @description     Function to filter the store data based on status selection from dropdown
   */
  componentDidUpdate(prevProps, state) {
    console.log("state",state.dcPrevSession);
    
    console.log("state1",this.state.dcPrevSession);
  }
  dropDownStoreMenuClicked = (menu, action) => {
    if(action != "undo"){
      let prevData = [...this.state.prevSession];
      prevData.push(menu);
      sessionStorage.setItem("storeStatus", menu);
      this.setState({
        dropDownMenuStore: false,
        currentStoreMenu: menu,
        prevSession: prevData
      });
    }else{
      sessionStorage.setItem("storeStatus", menu);
      this.setState({
        dropDownMenuStore: false,
        currentStoreMenu: menu
      });
    }

    if((this.state.orderTypeCode !== null && this.state.orderTypeCode !== "") ||
      (this.state.orderDate !== null && this.state.orderDate !== "")
    ) {
      if(menu === "All"){
        menu = "";
      } else if (menu === "Order Submitted") {
        menu = "received";
      } else if (menu === "In Transit") {
        menu = "intransit";
      } else if (menu === "Delivered") {
        menu = "delivered";
      } else if (menu === "Cancelled") {
        menu = "cancelled";
      } else if (menu === "On Hold") {
        menu = "onhold";
      }
      this.getStoreDetailSupplierStatusOrderDateOrderTypeList(
        this.state.storeNumber,
        this.state.divisionNumber,
        this.state.supplierNumber,
        menu,
        this.state.orderDateDB,
        this.state.orderTypeCode
      );
    }
    else {
    if (this.state.supplierNumber === "" && menu === "All") {
      this.getStoreDetailList(
        this.state.storeNumber,
        this.state.divisionNumber
      );
    } else if (this.state.supplierNumber !== "" && menu === "All") {
      this.getStoreDetailSupplierList(
        this.state.storeNumber,
        this.state.divisionNumber,
        this.state.supplierNumber
      );
    } else if (this.state.supplierNumber === "" && menu !== "All") {
      if (menu === "Order Submitted") {
        menu = "received";
      } else if (menu === "In Transit") {
        menu = "intransit";
      } else if (menu === "Delivered") {
        menu = "delivered";
      } else if (menu === "Cancelled") {
        menu = "cancelled";
      } else if (menu === "On Hold") {
        menu = "onhold";
      }
      this.getStoreDetailStatusList(
        this.state.storeNumber,
        this.state.divisionNumber,
        menu
      );
    } else if (this.state.supplierNumber !== "" && menu !== "All") {
      if (menu === "Order Submitted") {
        menu = "received";
      } else if (menu === "In Transit") {
        menu = "intransit";
      } else if (menu === "Delivered") {
        menu = "delivered";
      } else if (menu === "Cancelled") {
        menu = "cancelled";
      } else if (menu === "On Hold") {
        menu = "onhold";
      }
      this.getStoreDetailSupplierStatusList(
        this.state.storeNumber,
        this.state.divisionNumber,
        this.state.supplierNumber,
        menu
      );
    }
  }
  };

  /**
   * @description     Function to view the DC dropdown selection
   */
  dropDownOpenDC = () => {
    this.setState({
      dropDownMenuDC: !this.state.dropDownMenuDC,
    });
    if (this.state.dropDownMenuDC === false) {
      document.getElementById(
        "buttonDCDropDown"
      ).style.borderBottomRightRadius = "0px";
      document.getElementById("buttonDCDropDown").style.borderBottomLeftRadius =
        "0px";
    } else {
      document.getElementById(
        "buttonDCDropDown"
      ).style.borderBottomRightRadius = "17px";
      document.getElementById("buttonDCDropDown").style.borderBottomLeftRadius =
        "17px";
    }
    setTimeout(() => {
      try {
        document.getElementById(
          "dc " + this.state.currentDCMenu
        ).style.backgroundColor = "rgba(222, 245, 255, 0.8)";
        document.getElementById("dc " + this.state.currentDCMenu).style.color =
          "#4F4F4F";
        document.addEventListener("click", this.handleClickOutsideDC);
      } catch (error) { }
    }, 100);
  };

  handleClickOutsideDC = (event) => {
    if (
      this.dcWrapperRef &&
      this.dcWrapperRef.current !== null &&
      !this.dcWrapperRef.current.contains(event.target)
    ) {
      document.removeEventListener("click", this.handleClickOutsideDC);

      this.setState({
        dropDownMenuDC: false,
      });

      document.getElementById(
        "buttonDCDropDown"
      ).style.borderBottomRightRadius = "17px";
      document.getElementById("buttonDCDropDown").style.borderBottomLeftRadius =
        "17px";

      setTimeout(() => {
        try {
          document.getElementById(
            "dc " + this.state.currentDCMenu
          ).style.backgroundColor = "rgba(222, 245, 255, 0.8)";
          document.getElementById(
            "dc " + this.state.currentDCMenu
          ).style.color = "#4F4F4F";
        } catch (error) { }
      }, 100);
    }
  };

  /**
   * @description     Function to filter the DC data based on status selection from dropdown
   */
  dropDownDCMenuClicked = (menu, action) => {
    if(action != "undo"){
      let prevData = [...this.state.dcPrevSession];
      prevData.push(menu);
      sessionStorage.setItem("dcStatus", menu);
      this.setState({
        dropDownMenuDC: false,
        currentDCMenu: menu,
        dcPrevSession: prevData
      });
    }else{
      sessionStorage.setItem("dcStatus", menu);
      this.setState({
        dropDownMenuDC: false,
        currentDCMenu: menu,
      });
    }

    if (menu === "All") {
      this.getDCDetailList(this.state.distributionNumber);
    } else {
      if (menu === "In Transit") {
        menu = "intransit";
      } else if (menu === "Delivered") {
        menu = "delivered";
      }
      this.getDCDetailStatusList(this.state.distributionNumber, menu);
    }
  };
  /**
   * @description     Function to navigate to route page for store
   */
  viewStoreRouteDetails = (route, dcNumber, trips, showLocateTruck) => {
    sessionStorage.setItem("storeRoute", route);
    sessionStorage.setItem("storeDcNumber", dcNumber);
    sessionStorage.setItem("storeTrips", trips);
    sessionStorage.setItem("showLocateTruck", showLocateTruck);
    this.props.history.push("/routeInfoDetailPage");
  };
  /**
   * @description     Function to navigate to view store detail page
   */
  viewDetails = (
    storeOrderHeaderNumber,
    supplierName,
    supplierNumber,
    showException,
    orderDate,
    deliveryDate
  ) => {
    if (showException === "N") {
      sessionStorage.setItem("showException", "0");
    } else {
      sessionStorage.setItem("showException", "1");
    }
    sessionStorage.setItem("showRoute", "0");
    sessionStorage.setItem("orderHeaderNumber", storeOrderHeaderNumber);
    sessionStorage.setItem("supplierName", supplierName);
    sessionStorage.setItem("supplierStoreNumber", supplierNumber);
    sessionStorage.setItem("orderDate", orderDate);
    sessionStorage.setItem("deliveryDate", deliveryDate);
    this.props.history.push("/routeInfoDetailPage");
  };

  /**
   * @description     Function to navigate to route page for dc
   */
  viewRoute = (route, dcNumber, trips) => {
    sessionStorage.setItem("dcRoute", route);
    sessionStorage.setItem("dcNumber", dcNumber);
    sessionStorage.setItem("dcTrips", trips);
    this.props.history.push("/routeInfoDetailPage");
  };

  /**
   * @description     Function to navigate to home page
   */
  backHome = () => {
    //sessionStorage.clear();
    this.props.history.push("/");
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.alert && (
          <Snackbar
            open={this.state.open}
            autoHideDuration={1000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert onClose={this.handleClose} severity="error">
              Not able to retrieve data, please try after sometime!
            </Alert>
          </Snackbar>
        )}
        <Header />
        <RefreshOutlinedIcon
          aria-label="refresh-icon"
          className={styles.refreshImg}
          onClick={() => this.refreshClick()}
        />
        <HomeOutlinedIcon
          aria-label="home-icon"
          className={styles.homeImg}
          onClick={() => this.homeClick()}
        />
        
        <div className={styles.contentWrapper}>
          <div className={styles.splitRight}>
            <div className={styles.detailForm}>
              <div className={styles.detailFormHead}>
                <span>
                  <span className={styles.detailHeading}>
                    <img
                      src={backButton.default}
                      data-testid="backButton"
                      className={styles.imageStyleBackButton}
                      onClick={() => this.backHome()}
                      alt="imageStyleBackButton"
                    />
                    &nbsp; &nbsp;
                    {this.state.tabSelected === "store" && (
                      <span
                        className={styles.nameStyle}
                        data-testid="divisionStore"
                      >
                        {this.state.divisionName}, Store:{" "}
                        {this.state.storeNumber}
                      </span>
                    )}
                    {this.state.tabSelected === "distribution" && (
                      <span
                        className={styles.nameStyle}
                        data-testid="divisionDistribution"
                      >
                        {this.state.distributionName}
                      </span>
                    )}
                  </span>
                </span>
                <span className={styles.detailSelectDiv}>
                  {this.state.tabSelected === "store" && (
                    <span className="header-item">
                      <button
                        id="buttonStoreDropDown"
                        data-testid="buttonStoreDropDown"
                        onClick={() => this.dropDownOpenStore()}
                        ref={this.storeWrapperRef}
                      >
                        {this.state.currentStoreMenu}
                        <img
                          src={down.default}
                          alt=""
                          className={styles.imageDownArrow}
                        />
                      </button>
                      {this.state.dropDownMenuStore && (
                        <div className="header-sub-nav">
                          <div
                            className="header-sub-nav-item"
                            id="store All"
                            data-testid="storeAll"
                            onClick={() =>
                              this.dropDownStoreMenuClicked("All", "clicked")
                            }
                          >
                            All
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="store Cancelled"
                            data-testid="storeCancelled"
                            onClick={() =>
                              this.dropDownStoreMenuClicked(
                                "Cancelled",
                                "clicked"
                              )
                            }
                          >
                            Cancelled
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="store Delivered"
                            data-testid="storeDelivered"
                            onClick={() =>
                              this.dropDownStoreMenuClicked(
                                "Delivered",
                                "clicked"
                              )
                            }
                          >
                            Delivered
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="store In Transit"
                            data-testid="storeInTransit"
                            onClick={() =>
                              this.dropDownStoreMenuClicked(
                                "In Transit",
                                "clicked"
                              )
                            }
                          >
                            In Transit
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="store On Hold"
                            data-testid="storeOnHold"
                            onClick={() =>
                              this.dropDownStoreMenuClicked(
                                "On Hold",
                                "clicked"
                              )
                            }
                          >
                            On Hold
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="store Order Submitted"
                            data-testid="storeOrderSubmitted"
                            onClick={() =>
                              this.dropDownStoreMenuClicked(
                                "Order Submitted",
                                "clicked"
                              )
                            }
                          >
                            Order Submitted
                          </div>
                        </div>
                      )}
                    </span>
                  )}
                  {this.state.tabSelected === "distribution" && (
                    <span className="header-item">
                      <button
                        id="buttonDCDropDown"
                        data-testid="buttonDCDropDown"
                        onClick={() => this.dropDownOpenDC()}
                        ref={this.dcWrapperRef}
                      >
                        {this.state.currentDCMenu}
                        <img
                          src={down.default}
                          alt=""
                          className={styles.imageDownArrow}
                        />
                      </button>
                      {this.state.dropDownMenuDC && (
                        <div className="header-sub-nav">
                          <div
                            className="header-sub-nav-item"
                            id="dc All"
                            onClick={() =>
                              this.dropDownDCMenuClicked("All", "clicked")
                            }
                          >
                            All
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="dc Delivered"
                            onClick={() =>
                              this.dropDownDCMenuClicked("Delivered", "clicked")
                            }
                          >
                            Delivered
                          </div>
                          <div
                            className="header-sub-nav-item"
                            id="dc In Transit"
                            data-testid="dcInTransit"
                            onClick={() =>
                              this.dropDownDCMenuClicked(
                                "In Transit",
                                "clicked"
                              )
                            }
                          >
                            In Transit
                          </div>
                        </div>
                      )}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className={styles.detailForm1}>
              {this.state.tabSelected === "store" && (
                <div className={styles.detailDiv}>
                  {this.state.dataListStore.map((value, index) => {
                    return this.renderDetailListStore(value, index);
                  })}
                  {this.state.loaderStore && (
                    <div className={styles.backdrop}>
                      <div className={styles.loaderDiv}>
                        <div className="loader" />
                        <div className={styles.loadingMsg}>Loading...</div>
                      </div>
                    </div>
                  )}
                  {this.state.storeNoDataMsg === true && (
                    <div className={styles.emptyDiv} data-testid="storeNoDataDiv">
                      <img
                        src={emptyImg.default}
                        alt="imageStoreStyleEmpty"
                        className={styles.imageStyleEmpty}
                      />
                      <div className={styles.emptyMsg}>No Orders Available</div>
                      <div className={styles.emptyMsgDesc}>
                        <div>
                          There are no orders available for the division
                        </div>
                        <div>and store that you have selected</div>
                      </div>
                      <div>
                        <button
                          className={styles.buttonTryAgain}
                          onClick={() => this.tryAgain("store")}
                        >
                         Select Again
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.tabSelected === "distribution" && (
                <div className={styles.detailDiv}>
                  {this.state.dataListDC.map((value, index) => {
                    return this.renderDetailListDC(value, index);
                  })}
                  {this.state.loaderDc && <div className="loader" />}
                  {this.state.loaderDc && (
                    <div className={styles.backdrop}>
                      <div className={styles.loaderDiv}>
                        <div className="loader" />
                        <div className={styles.loadingMsg}>Loading...</div>
                      </div>
                    </div>
                  )}
                  {this.state.dcNoDataMsg === true && (
                    <div className={styles.emptyDiv}>
                      <img
                        src={emptyImg.default}
                        alt="imageDistributionStyleEmpty"
                        className={styles.imageStyleEmpty}
                      />
                      <div className={styles.emptyMsg}>No Orders Available</div>
                      <div className={styles.emptyMsgDesc}>
                        <div>There are no orders available for the DC that</div>
                        <div>you have selected</div>
                      </div>
                      <div>
                        <button
                          className={styles.buttonTryAgain}
                          onClick={() => this.tryAgain("dc")}
                        >
                          Select Again
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDetailListStore = (value, index) => {
    try {
      const classes = [];
	  let statusVerbiage = "Estimated Delivery";
	  var estTime = value.estimatedTime? moment(value.estimatedTime).format("lll") :
                  moment(value.estimatedDelivery).format("ll");
      if (value.orderStatus === "delivered") {
        classes.push("green");
        statusVerbiage = "Completed";
        estTime = value.actualTime? moment(value.actualTime).format("lll"):estTime;
      }
      if (value.orderStatus === "intransit") {
        classes.push("blue");
        statusVerbiage ="Est. Deliv.";
      }
      if (value.orderStatus === "onhold") {
        classes.push("orange");
      }
      if (value.orderStatus === "received") {
        classes.push("darkOrange");
      }
      if (value.orderStatus === "cancelled") {
        classes.push("red");
      }
      var toolTipEstTime = value.dcTimeZoneName ? statusVerbiage+": " +estTime+ " "+value.dcTimeZoneName :'';
      return (
        <div>
          <div id={"rt-" + index} className={styles.detailBoxDateHeadStore}>
            <div className={styles.detailStoreGroupHeaderSection}>
              <div className={styles.detailStoreGroupStatusSection}>
                <span className={classes.join(" ")}>
                  {value.orderStatus === "delivered" && (
                    <img
                      src={deliveredImg.default}
                      alt=""
                      className={styles.imageStyleStatusButton}
                    />
                  )}
                  {value.orderStatus === "intransit" && (
                    <img
                      src={transitImg.default}
                      alt=""
                      className={styles.imageStyleStatusButton}
                    />
                  )}
                  {value.orderStatus === "onhold" && (
                    <img
                      src={holdImg.default}
                      alt=""
                      className={styles.imageStyleStatusButton}
                    />
                  )}
                  {value.orderStatus === "received" && (
                    <img
                      src={submittedImg.default}
                      alt=""
                      className={styles.imageStyleStatusButton}
                    />
                  )}
                  {value.orderStatus === "cancelled" && (
                    <img
                      src={cancelledImg.default}
                      alt=""
                      className={styles.imageStyleStatusButton}
                    />
                  )}
                  &nbsp;
                  {value.orderStatus === "delivered" && "Delivered"}
                  {value.orderStatus === "intransit" && "In Transit"}
                  {value.orderStatus === "onhold" && "On Hold"}
                  {value.orderStatus === "received" && "Order Submitted"}
                  {value.orderStatus === "cancelled" && "Cancelled"}
                </span>
              </div>
              <div className={styles.detailBoxRouteDateSection}>
                <div className={styles.detailBoxRouteDateSectionWrap}>
                  <div className={styles.detailBoxRouteSection}>
                    Route:&nbsp;
                    {value.routeCd ? value.routeCd : "N/A"}
                  </div>
                  <div title={toolTipEstTime} className={styles.detailBoxEstDateSection}>
                    {statusVerbiage}:{" "}
                    {estTime}
                  </div>
                </div>
              </div>
              <div className={styles.detailPalletCount}>
                Pallet Count :{""} {value.palletCount}
              </div>
            </div>
            <div className={styles.detailHeadBoxStore}>
              <div className={styles.detailHeadStore}>
                <div className={styles.detailHeadTitlesSupplier}>Supplier</div>
                <div className={styles.detailHeadQtyTitles}>
                  <div className={styles.detailHeadQtyTitlesWrap}>
                    <div className={styles.detailHeadTitlesQtyOrdered}>
                      Qty Ordered
                    </div>
                    <div className={styles.detailHeadTitlesQtyShipped}>
                      Qty Shipped
                    </div>
                  </div>
                </div>
                <div className={styles.emptySpan}>
                  {value.orderList[0].showLocateTruck && (
                    <button
                      className={styles.buttonRouteInfoStore}
                      onClick={() =>
                        this.viewStoreRouteDetails(
                          value.orderList[0].routeCd,
                          value.orderList[0].distributionCentre,
                          value.orderList[0].trips,
                          value.orderList[0].showLocateTruck
                        )
                      }
                    >
                      {value.routeCd && "Route " + value.routeCd + " Info"}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div>
              {value.orderList.map((value, storeIndex) => {
                return this.renderDetailListStoreGroup(
                  value,
                  index,
                  storeIndex
                );
              })}
            </div>
            <div className={styles.detailStoreGroupFooterSection}></div>
          </div>
        </div>
      );
    } catch (err) { }
  };

  renderDetailListStoreGroup = (value, index, storeIndex) => {
    var supplierValue =
      value && value.supplier + "-" + value.supplierName.trim().replace(/\0/g, "");

    try {
      return (
        <div className={styles.detailBoxStore} key={value.route}>
          <div className={styles.detailBoxDetailDivStore}>
            <Tooltip
              arrow
              title={
                supplierValue.length >= 17
                  ? value.supplier +
                  "-" +
                  value.supplierName.trim().replace(/\0/g, "")
                  : ""
              }
            >
              <div className={styles.detailBoxHeadRoute}>
                {value.supplier}-{value.supplierName.trim().replace(/\0/g, "")}
              </div>
            </Tooltip>
            <div className={styles.detailBoxTotalQtySection}>
              <div className={styles.detailBoxTotalQtySectionWrap}>
                <div className={styles.detailBoxTotalOrderQty}>
                  <div className={styles.detailBoxDetailSectionSub}>
                    {Math.trunc(value.totalOrderQuantity)}
                  </div>
                </div>
                <div className={styles.detailBoxTotalShippedQty}>
                  {value.totalShippedQuantity !== null ? (
                    <div className={styles.detailBoxDetailSectionSub}>
                      {Math.trunc(value.totalShippedQuantity)}
                    </div>
                  ) : (
                      <div className={styles.detailBoxDetailSectionSub}>0</div>
                    )}
                </div>
              </div>
            </div>
            <div className={styles.buttonViewDetailsSection}>
              {(value.dtlFlag === "Y" || value.excpFlag === "Y") && (
                <button
                  data-testid="buttonViewRouteStore"
                  id={index + "-view-" + storeIndex}
                  className={styles.buttonViewRouteStore}
                  onClick={() =>
                    this.viewDetails(
                      value.orderHeaderNumber,
                      value.supplierName,
                      value.supplier,
                      value.excpFlag,
                      value.orderOnForPrint,
                      value.estimatedDelivery
                    )
                  }
                >
                  View Details
                  <ChevronRightIcon className={styles.rightArrowIcon} />
                </button>
              )}
            </div>
          </div>
          <br />
        </div>
      );
    } catch (err) { }
  };

  renderDetailListDC = (value, index) => {
    try {
      return (
        <div>
          <div id={index} className={styles.detailBoxDateHead}>
            <div className={styles.detailBoxDateSection}>
              Estimated Delivery :{" "}
              {moment(value.estimatedDelivery).format("ll")}
            </div>

            <div>
              {value.dcOrderList.map((value, dcIndex) => {
                return this.renderDetailListDCList(value, index, dcIndex);
              })}
            </div>
          </div>
        </div>
      );
    } catch (err) { }
  };

  renderDetailListDCList = (value, index, dcIndex) => {
    try {
      const classes = [];
      if (value.orderStatus === "delivered") {
        classes.push("green");
      }
      if (value.orderStatus === "intransit") {
        classes.push("blue");
      }

      return (
        <div
          id={index + "-" + dcIndex}
          className={styles.detailBoxDC}
          key={value.route}
        >
          <div className={styles.detailBoxDetailDivDC}>
            <div className={styles.detailBoxHeadDivDC}>
              <span className={classes.join(" ")}>
                {value.orderStatus === "delivered" && (
                  <img
                    src={deliveredImg.default}
                    alt=""
                    className={styles.imageStyleStatusButton}
                  />
                )}
                {value.orderStatus === "intransit" && (
                  <img
                    src={transitImg.default}
                    alt=""
                    className={styles.imageStyleStatusButton}
                  />
                )}
                &nbsp;
                {value.orderStatus === "delivered" && "Delivered"}
                {value.orderStatus === "intransit" && "In Transit"}
              </span>
            </div>
            <div>
              <div className={styles.detailBoxDetailSectionHead}>Route</div>
              <div className={styles.detailBoxDetailSectionSub}>
                {value.routeCd}
              </div>
            </div>
            <div className={styles.detailBoxDetailSection}>
              <div className={styles.detailBoxDetailSectionHead}>
                Pallet Count
              </div>
              <div className={styles.detailBoxDetailSectionSub}>
                {value.palletCount}
              </div>
            </div>
            <div>
              <div className={styles.detailBoxDetailSectionHead}>
                Total Units
              </div>
              <div className={styles.detailBoxDetailSectionSub}>
                {Math.trunc(value.totalUnits)}
              </div>
            </div>
            <div>
              <div className={styles.detailBoxDetailSectionHead}>Stops</div>
              <div className={styles.detailBoxDetailSectionSub}>
                {value.numberOfStops}
              </div>
            </div>
            <button
              className={styles.buttonViewRouteDC}
              onClick={() =>
                this.viewRoute(
                  value.routeCd,
                  value.distributionCentre,
                  value.trips
                )
              }
            >
              Route Info
            </button>
          </div>
          <br />
        </div>
      );
    } catch (err) { }
  };
}
export default withRouter(StoreDetail);
