import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "../assets/css/home.module.css";
import { Header } from "./Header";
import Select from "react-select";
import Helmet from "react-helmet";
import { FetchWrapper } from "../util/FetchWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';

const background = require("../assets/images/login_bg.jpg");

export class HomePage extends Component {
  constructor(props) {
    super(props);
    sessionStorage.setItem("homeClicked", false);
    this.state = {
      storeTab: true,
      storeTabSelected: "store",
      optionDivision: [],
      optionStore: [],
      optionSupplier: [],
      optionOrderDate: [],
      optionStoreStatus: [
        { status: "Cancelled" },
        { status: "Delivered" },
        { status: "In Transit" },
        { status: "On Hold" },
        { status: "Order Submitted" },
      ],
      optionOrderCodeType : [
        /* { Code : "FAR", Type : "FaR"},
        { Code : "OF", Type : "Firm"},
        { Code : "R", Type : "Legacy DSD"},
        { Code : "MFC", Type : "MFC"},
        { Code : "PL", Type : "Regular"},
        { Code : "DSD", Type : "Regular DSD"},
        { Code : "RU", Type : "Rush"}     */
        { Code : "DSD", Type : "DSD"},
        { Code : "FaR", Type : "FaR"},
        { Code : "Firm", Type : "Firm"},
        { Code : "MFC", Type : "MFC"},
        { Code : "Regular", Type : "Regular"},
        { Code : "Rush", Type : "Rush"},
        { Code : "Unknown", Type : "Unknown"}
                    
      ],
      optionDC: [],
      optionDCStatus: [{ status: "Delivered" }, { status: "In Transit" }],
      selectedOptionDivison: "",
      selectedOptionStore: "",
      selectedOptionSupplier: "",
      selectedOptionStoreStatus: "",
      selectedOptionOrderDate: "",
      selectedoptionOrderCodeType: "",
      selectedOptionDC: "",
      selectedOptionDCStatus: "",
      isErrDivision: false,
      isErrStore: false,
      isErrOrderDate: false,
      isErrDC: false,
      loaderStore: "",
      loaderDivision: "",
      alert: "",
      info: "",
      open: false,
      selectedOptionDivisionData: [],
      selectedOptionOrderDateData: [],
      selectedOptionOrderCodeTypeData: [],
      divisionOpt: null,
      storeOpt: null,
      supplierOpt: null,
      storeStatusOpt: null,
      orderDateOpt: null,
      orderTypeCodeOpt: null,
      dcOpt: null,
      dcStatusOpt: null,
    };
  }

  componentDidMount() {
    sessionStorage.setItem("home", true);

    /*if (
      sessionStorage.getItem("storeStatusOpt") !== null &&
      sessionStorage.getItem("storeStatusOpt") !== ""
    ) {
      sessionStorage.setItem(
        "storeStatus",
        sessionStorage.getItem("storeStatusOpt")
      );
    } else {
      sessionStorage.setItem("storeStatus", "All");
    }

    if (
      sessionStorage.getItem("orderTypeCodeOpt") !== null &&
      sessionStorage.getItem("orderTypeCodeOpt") !== ""
    ) {
      sessionStorage.setItem(
        "type",
        sessionStorage.getItem("orderTypeCodeOpt")
      );
    } else {
      sessionStorage.setItem("type", "");
      sessionStorage.setItem("code", "");
    }

    if (
      sessionStorage.getItem("orderDateOpt") !== null &&
      sessionStorage.getItem("orderDateOpt") !== ""
      
    ) {
      sessionStorage.setItem(
        "orderDateFilter",
        sessionStorage.getItem("orderDateOpt")
      );
    } else {
      sessionStorage.removeItem("orderDateFilter");
    } 


    if (
      sessionStorage.getItem("dcStatusOpt") !== null &&
      sessionStorage.getItem("dcStatusOpt") !== ""
    ) {
      sessionStorage.setItem("dcStatus", sessionStorage.getItem("dcStatusOpt"));
    } else {
      sessionStorage.setItem("dcStatus", "All");
    }
    this.getOrderDateList();
    this.getDivisionList();
    if (
      sessionStorage.getItem("divisionNumber") !== null &&
      sessionStorage.getItem("divisionNumber") !== undefined
    ) {
      this.getStoreSupplierList(sessionStorage.getItem("divisionNumber"));
    }
    this.setState({
      divisionOpt: sessionStorage.getItem("divisionName"),
      storeOpt: sessionStorage.getItem("storeNumber"),
      supplierOpt: sessionStorage.getItem("supplierOpt"),
      storeStatusOpt: sessionStorage.getItem("storeStatusOpt"),
      orderTypeCodeOpt: sessionStorage.getItem("orderTypeCodeOpt"),
      orderDateOpt: sessionStorage.getItem("orderDateOpt"),
      dcOpt: sessionStorage.getItem("distributionName"),
      dcStatusOpt: sessionStorage.getItem("dcStatusOpt"),
    });

    let tabSelected = sessionStorage.getItem("tabSelected");
    if (tabSelected !== null) {
      if (tabSelected === "store" || tabSelected === null) {
        this.setState({
          storeTab: true,
          storeTabSelected: tabSelected,
          divisionOpt: sessionStorage.getItem("divisionName"),
          storeOpt: sessionStorage.getItem("storeNumber"),
          supplierOpt: sessionStorage.getItem("supplierOpt"),
          storeStatusOpt: sessionStorage.getItem("storeStatusOpt"),
          orderTypeCodeOpt: sessionStorage.getItem("orderTypeCodeOpt"),
          orderDateOpt: sessionStorage.getItem("orderDateOpt"),
        });
      } else {
        this.setState({
          storeTab: false,
          storeTabSelected: tabSelected,
          dcOpt: sessionStorage.getItem("distributionName"),
          dcStatusOpt: sessionStorage.getItem("dcStatusOpt"),
        });
      }
    } */
    this.getOrderDateList();
    this.getLastSearchCriteriaList(sessionStorage.getItem('userId'));
  }
  getLastSearchCriteriaList = async (userId) => {
    this.setState({
      loaderSearch: 1,
    });
    FetchWrapper("/user/usersearchinfo/" + userId, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
      
		  if(data.lastSearchPane !== '' && data.lastSearchPane !== null)
		  {
				sessionStorage.setItem("userId", data.userId);
				sessionStorage.setItem("divisionNumber", data.divisionId !== null ? data.divisionId.trim() : '');
				sessionStorage.setItem("storeNumber", data.storeId !== null ? data.storeId : '');
				sessionStorage.setItem("supplierNumber", data.supplierId !== null ? data.supplierId : '');
				sessionStorage.setItem("storeStatus", data.orderStatus !== null ?  data.orderStatus : '');
        if (data.orderStatus === "received") {
          sessionStorage.setItem("storeStatusOpt", "Order Submitted");
        } else if (data.orderStatus === "intransit") {
          sessionStorage.setItem("storeStatusOpt", "In Transit");
        } else if (data.orderStatus === "delivered") {
          sessionStorage.setItem("storeStatusOpt", "Delivered");
        } else if (data.orderStatus === "cancelled") {
          sessionStorage.setItem("storeStatusOpt", "Cancelled");
        } else if (data.orderStatus === "onhold") {
          sessionStorage.setItem("storeStatusOpt", "On Hold");
        } else if (data.orderStatus === null || data.orderStatus === 'All') {
          sessionStorage.setItem("storeStatusOpt", "");
        }
				sessionStorage.setItem("distributionNumber", data.dcId !== null ? data.dcId : '');
				sessionStorage.setItem("dcStatus", data.dcOrderStatus !== null ? data.dcOrderStatus : '');
        if (data.dcOrderStatus === "intransit") {
          sessionStorage.setItem("dcStatusOpt", "In Transit");
        } else if (data.dcOrderStatus === "delivered") {
          sessionStorage.setItem("dcStatusOpt", "Delivered");
        } else if(data.dcOrderStatus === null || data.dcOrderStatus === 'All') {
          sessionStorage.setItem("dcStatusOpt", "");
        }
				sessionStorage.setItem("lastSearchPane", data.lastSearchPane.toUpperCase());
        data.lastSearchPane.toUpperCase() !== 'D' ?  
                sessionStorage.setItem("tabSelected", "store") : 
                sessionStorage.setItem("tabSelected", "distribution")
		  }
      this.getDivisionList();
				
      this.setState({
        //divisionOpt: sessionStorage.getItem("divisionName"),
        storeOpt: sessionStorage.getItem("storeNumber") !== '' ? sessionStorage.getItem("storeNumber") : null,
        //supplierOpt: sessionStorage.getItem("supplierOpt") !== '' ? sessionStorage.getItem("supplierOpt") : null,
        storeStatusOpt: sessionStorage.getItem("storeStatusOpt") !== '' && 
                        sessionStorage.getItem("storeStatusOpt") !== null ? 
                        sessionStorage.getItem("storeStatusOpt") : null,
        orderTypeCodeOpt: sessionStorage.getItem("type"),
        orderDateOpt: sessionStorage.getItem("orderDateOpt"),
        // dcOpt: sessionStorage.getItem("distributionName"),
        dcStatusOpt: sessionStorage.getItem("dcStatusOpt") !== '' && 
                     sessionStorage.getItem("dcStatusOpt") !== null ?
                     sessionStorage.getItem("dcStatusOpt") : null 
      });
		   let tabSelected = sessionStorage.getItem("tabSelected");
    if (tabSelected !== null) {
      //Starts here
      this.setState({
        divisionOpt: sessionStorage.getItem("divisionName") !== '' ? sessionStorage.getItem("divisionName") : null,
        storeOpt: sessionStorage.getItem("storeNumber") !== '' ? sessionStorage.getItem("storeNumber") : null,
        supplierOpt: sessionStorage.getItem("supplierOpt") !== '' ? sessionStorage.getItem("supplierOpt") : null,
        storeStatusOpt: sessionStorage.getItem("storeStatusOpt"),
        orderTypeCodeOpt: sessionStorage.getItem("type"),
        orderDateOpt: sessionStorage.getItem("orderDateOpt"),
        dcOpt: sessionStorage.getItem("distributionName") !== '' ? 
                 sessionStorage.getItem("distributionName") : null,
        dcStatusOpt: sessionStorage.getItem("dcStatusOpt") !== '' ?
                       sessionStorage.getItem("dcStatusOpt") : null,
      });
      //Ends here
      if (tabSelected === "store" || tabSelected === null) {
		  
        this.setState({
          storeTab: true,
          storeTabSelected: tabSelected,
          /*divisionOpt: sessionStorage.getItem("divisionName") !== '' ? sessionStorage.getItem("divisionName") : null,
          storeOpt: sessionStorage.getItem("storeNumber") !== '' ? sessionStorage.getItem("storeNumber") : null,
          supplierOpt: sessionStorage.getItem("supplierOpt") !== '' ? sessionStorage.getItem("supplierOpt") : null,
          storeStatusOpt: sessionStorage.getItem("storeStatusOpt"),
          sourceTypeCodeOpt: sessionStorage.getItem("sourceTypeCodeOpt"),
          orderDateOpt: sessionStorage.getItem("orderDateOpt"), */
        });
      } else {
        this.setState({
          storeTab: false,
          storeTabSelected: tabSelected,
          /*dcOpt: sessionStorage.getItem("distributionName") !== '' ? 
                 sessionStorage.getItem("distributionName") : null,
          dcStatusOpt: sessionStorage.getItem("dcStatusOpt") !== '' ?
                       sessionStorage.getItem("dcStatusOpt") : null,*/
        });
      }
    }
      })
      .catch((error) => {
        console.error(error);
        this.getDivisionList();
        this.setState({
          alert: 1,
          open: true,
          loaderSearch: "",
        });
      });
  };
 
/* Added on 10-05-2022
Starts here
*/

/**
* @description Function to get the Order Date list
*/
getOrderDateList = () => {
	let orderDateList = [];
	let startDate = new Date()
	startDate.setDate( startDate.getDate() - 20 );
	let stopDate = new Date();
	let dateArray = new Array();
	let tmpStartDate = startDate;
	while (tmpStartDate <= stopDate) {
		dateArray.push(tmpStartDate)
		let dat = new Date(tmpStartDate)
		dat.setDate(dat.getDate() + 1)
			tmpStartDate = dat;
	}
	let dayName = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
	if(dateArray.length <= 0)
	{
		this.setState({
		alert: 1,
		open: true,
		});
	}
	else
	{
		for (let i = dateArray.length-1; i >= 0; i -- ) 
		{
			orderDateList.push({
        orderDateDB: `${dateArray[i].getFullYear()}-${(dateArray[i].getMonth()+1).toString().padStart(2, "0")}-${dateArray[i].getDate().toString().padStart(2, "0")}`,				
        orderDate: `${dateArray[i].getFullYear()} ${dayName[dateArray[i].getDay()]} ${dateArray[i].toLocaleString("en-US", { month: "short" })} ${dateArray[i].getDate()}`,
			});
		}
			this.setState({
				optionOrderDate: orderDateList,
			});
	}
};

//Ends here
  /**
   * @description     Function to get the division list
   */
  getDivisionList = async () => {
    let divisionList = [];
    this.setState({
      loaderDivision: 1,
    });
    FetchWrapper("/store/division/userDivision/"+sessionStorage.getItem("userDivisionId")+"/"+sessionStorage.getItem("username"), "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data.divisionList) {
          if (data.divisionList.length <= 0) {
            this.setState({
              alert: 1,
              open: true,
            });
          }
        }else{
			this.setState({
              info: 1,
              open: true,
              divisionOpt: null,
              storeOpt: null,
              supplierOpt: null
            });
		}
        for (let dataDivision of data.divisionList) {
          divisionList.push({
            divisionNumber: dataDivision.divisionNumber,
            divisionName: dataDivision.divisionName.toLowerCase(),
          });
        }
        this.setState({
          optionDivision: divisionList,
          optionDC: data.dcList,
          loaderDivision: "",
        }, () => {
          let tempDivisionList = this.state.optionDivision;
          let tempDCList = this.state.optionDC;
          let filterDataDC = []; 
          let divNum = sessionStorage.getItem("divisionNumber");
          let dcNum = sessionStorage.getItem("distributionNumber");
          let suppNo = sessionStorage.getItem("supplierNumber");
          let filterData = [];
          let lsp = sessionStorage.getItem("lastSearchPane");
          if(divNum !== null && divNum !== '')
          {
              filterData = tempDivisionList.filter((x) => {
              return x.divisionNumber === divNum;
              });
              if(filterData.length > 0) {
              sessionStorage.setItem("divisionName", filterData[0].divisionName);
              }else{
                this.setState({
                  storeOpt: null,
                  supplierOpt: null
                });
              }
          }
          else{
            sessionStorage.setItem("divisionName", "");
            this.setState({
                  storeOpt: null,
                  supplierOpt: null
                });
          }
          if(dcNum !== null && dcNum !== '' && tempDCList!==null && tempDCList.length > 0)
          {
            filterDataDC = tempDCList.filter((x) => {
              return x.dcId === dcNum;
              });
              if(filterDataDC.length > 0) {
              sessionStorage.setItem("distributionName", filterDataDC[0].dcName);
              }
          }
          else{
            sessionStorage.setItem("distributionName", "");
          }
          if(lsp !== null)
          {
            if(lsp.toUpperCase() !== 'S'){
            this.setState({
              dcOpt: sessionStorage.getItem("distributionName") !== '' ? 
                     sessionStorage.getItem("distributionName") : null
            });
          }
          }

          if(divNum !== '' && filterData !==null && filterData.length > 0) {
            this.getStoreSupplierList(divNum, 'load');
          }
          else{
            this.setState({
              divisionOpt: null
          });
          }
          if(suppNo === '') {
            this.setState({
              supplierOpt: null
            });
          }
          
          
        });
      })
      .catch((error) => {
        console.error(error);
        if(this.state.info) {
		this.setState({
          info: 1,
          open: true,
          loaderDivision: "",
        });
		}else{
        this.setState({
          alert: 1,
          open: true,
          loaderDivision: "",
        });
        }
      });
  };

  /**
   * @description     Tab Selection for Store & DC
   */
  tabSelection = (clicked) => {
    if (clicked === "store") {
      this.setState({
        storeTab: true,
        isErrDivision: false,
        isErrStore: false,
        isErrDC: false,
        selectedOptionDC: "",
        selectedOptionDCStatus: "",
        storeTabSelected: clicked,
      });
    } else {
      this.setState({
        storeTab: false,
        isErrDivision: false,
        isErrStore: false,
        isErrDC: false,
        selectedOptionDivison: "",
        selectedOptionStore: "",
        selectedOptionSupplier: "",
        selectedOptionStoreStatus: "",
        selectedOptionOrderDate: "",
        selectedoptionOrderCodeType: "",
        storeTabSelected: clicked,
      });
    }
  };

  /**
   * @description     Function to get the store & dc data list
   */
  trackOrder = () => {
    if (this.state.storeTabSelected === "store") {
      if (
        (this.state.selectedOptionDivison === null ||
          this.state.selectedOptionDivison=== "" ||
          this.state.selectedOptionDivison.value === undefined ||
          this.state.selectedOptionDivison.value.length ===0) &&
        (this.state.divisionOpt === null ||
          this.state.divisionOpt === undefined)
      ) {
        this.setState({
          isErrDivision: true,
        });
      } else {
        this.setState({
          isErrDivision: false,
        });
      }

      if (
        (this.state.selectedOptionStore === null ||
          this.state.selectedOptionStore.value === undefined) &&
        (this.state.storeOpt === null || this.state.storeOpt === undefined)
      ) {
        this.setState({
          isErrStore: true,
        });
      } else {
        this.setState({
          isErrStore: false,
        });
      }

      if (
        (this.state.selectedOptionDivison !== null ||
          this.state.divisionOpt !== null) &&
        (this.state.selectedOptionStore !== null ||
          this.state.storeOpt !== null)
      ) {
        if (
          (this.state.selectedOptionDivison.value !== undefined ||
            this.state.divisionOpt !== undefined) &&
          (this.state.selectedOptionStore.value !== undefined ||
            this.state.storeOpt != undefined)
        ) {
          if (
            (this.state.selectedOptionSupplier !== "" &&
              this.state.selectedOptionSupplier !== null &&
              this.state.selectedOptionSupplier.value !== undefined) ||
            (sessionStorage.getItem("supplierNumber") !== null &&
              sessionStorage.getItem("supplierNumber") !== undefined &&
              sessionStorage.getItem("supplierNumber") !== "")
          ) {
            sessionStorage.setItem(
              "supplierNumber",
              this.state.selectedOptionSupplier !== "" &&
                this.state.selectedOptionSupplier !== null &&
                this.state.selectedOptionSupplier.value !== undefined
                ? this.state.selectedOptionSupplier.value.slice(0, 4)
                : sessionStorage.getItem("supplierNumber")
            );

            sessionStorage.setItem(
              "supplierOpt",
              this.state.selectedOptionSupplier !== "" &&
                this.state.selectedOptionSupplier !== null &&
                this.state.selectedOptionSupplier.value !== undefined
                ? this.state.selectedOptionSupplier.value
                : sessionStorage.getItem("supplierOpt")
            );
          } else {
            sessionStorage.setItem("supplierNumber", "");
          }
          if (sessionStorage.getItem("code") === null ||
          sessionStorage.getItem("code") === undefined ||
          sessionStorage.getItem("code") === "")
          {
            sessionStorage.setItem("code", "")
          }
          if (sessionStorage.getItem("orderDateDB") === null ||
          sessionStorage.getItem("orderDateDB") === undefined ||
          sessionStorage.getItem("orderDateDB") === "")
          {
            sessionStorage.setItem("orderDateDB", "")
          }
          sessionStorage.setItem(
            "divisionNumber",
            this.state.selectedOptionDivisionData.divisionNumber !== null &&
              this.state.selectedOptionDivisionData.divisionNumber !== undefined
              ? this.state.selectedOptionDivisionData.divisionNumber
              : sessionStorage.getItem("divisionNumber")
          );

          sessionStorage.setItem(
            "divisionName",
            this.state.selectedOptionDivisionData.divisionName !== null &&
              this.state.selectedOptionDivisionData.divisionName !== undefined
              ? this.state.selectedOptionDivisionData.divisionName.toLowerCase()
              : this.state.divisionOpt
          );

          sessionStorage.setItem(
            "storeNumber",
            this.state.selectedOptionStore.value !== null &&
              this.state.selectedOptionStore.value !== undefined
              ? this.state.selectedOptionStore.value
              : this.state.storeOpt
          );
          sessionStorage.setItem("tabSelected", this.state.storeTabSelected);
          this.props.history.push("/storeDetail");
        }
      }
    } else {
      if (
        (this.state.selectedOptionDC === null ||
          this.state.selectedOptionDC === undefined ||
          this.state.selectedOptionDC === "") &&
        (this.state.dcOpt === null || this.state.dcOpt === undefined)
      ) {
        this.setState({
          isErrDC: true,
        });
      } else {
        this.setState({
          isErrDC: false,
        });
      }

      if (
        (this.state.selectedOptionDC !== null &&
          this.state.selectedOptionDC !== "") ||
        this.state.dcOpt !== null
      ) {
        if (
          this.state.selectedOptionDC.value !== undefined ||
          this.state.dcOpt !== undefined
        ) {
          sessionStorage.setItem(
            "distributionNumber",
            this.state.selectedOptionDCData !== null &&
              this.state.selectedOptionDCData !== undefined
              ? this.state.selectedOptionDCData.dcId
              : sessionStorage.getItem("distributionNumber")
          );
          sessionStorage.setItem(
            "distributionName",
            this.state.selectedOptionDCData !== null &&
              this.state.selectedOptionDCData !== undefined
              ? this.state.selectedOptionDCData.dcName
              : sessionStorage.getItem("distributionName")
          );
          sessionStorage.setItem("tabSelected", this.state.storeTabSelected);
          this.props.history.push("/storeDetail");
        }
      }
    }
  };

  /**
   * @description     Function to get the selected division
   */
  handleChangeDivison = (selectedOptionDivison) => {
    let tempDivisionList = this.state.optionDivision;
    let filterData = [];
    let filteredValue = "";

    sessionStorage.removeItem("divisionNumber");
    sessionStorage.removeItem("divisionName");
    sessionStorage.removeItem("storeNumber");
    sessionStorage.removeItem("supplierNumber");
    sessionStorage.removeItem("supplierOpt");
    sessionStorage.removeItem("storeStatusOpt");
    sessionStorage.removeItem("orderTypeCodeOpt");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("orderDateFilter");
    sessionStorage.removeItem("orderDateOpt");
    sessionStorage.removeItem("orderDateDB");
    sessionStorage.setItem("storeStatus", "All");

    this.setState({
      selectedOptionDivison,
      divisionOpt: null,
      storeOpt: null,
      supplierOpt: null,
      storeStatusOpt: null,
      orderDateOpt: null,
      orderTypeCodeOpt: null,
      isErrDivision: false
    });

    if (selectedOptionDivison !== null) {
      this.setState({
        divisionOpt: selectedOptionDivison && selectedOptionDivison.value,
      });
      if (tempDivisionList !== undefined) {
        this.setState({
          open: false,
        });
      }
      filterData = tempDivisionList.filter((x) => {
        return x.divisionName === selectedOptionDivison.value;
      });
      this.setState({
        selectedOptionDivisionData: filterData[0],
      });
      filteredValue = filterData[0] && filterData[0].divisionNumber;
      this.getStoreSupplierList(filteredValue, 'change');
    } else {
      this.setState({
        optionStore: [],
        optionSupplier: [],
        selectedOptionStore: "",
        selectedOptionSupplier: "",
      });
    }
  };

  /**
   * @description     Function to get the store list & supplier list
   */
  getStoreSupplierList = async (divisionNumber, eve) => {
    this.setState({
      selectedOptionStore: "",
      selectedOptionSupplier: "",
      loaderStore: 1,
    });

    let supplierList = [];
    let storeList = [];

    FetchWrapper("/store/division/" + divisionNumber, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data.storeList && data.supplierList) {
          if (data.storeList.length <= 0 || data.supplierList.length <= 0) {
            this.setState({
              alert: 1,
              open: true,
            });
          }
        }
        if (data !== undefined) {
          if (data.storeList !== null) {
            storeList = data.storeList;
          } else {
            storeList = [];
          }

          if (data.supplierList !== null) {
            for (let i = 0; i < data.supplierList.length; i++) {
              supplierList.push({
                supNumber: data.supplierList[i].supNumber,
                supName: data.supplierList[i].supName,
                supplier:
                  data.supplierList[i].supNumber +
                  "-" +
                  data.supplierList[i].supName,
              });
            }
          } else {
            supplierList = [];
          }
        }

        this.setState({
          optionStore: storeList,
          optionSupplier: supplierList,
          loaderStore: "",
        }, () => {
          if(eve === 'load')
          {
            let tempSupplierList = this.state.optionSupplier;
            let filterDataSupp = [];
            let suppNum = sessionStorage.getItem("supplierNumber");
            if(suppNum !== null && suppNum !== '')
            {
              filterDataSupp = tempSupplierList.filter((x) => {
                return x.supNumber === suppNum;
                });
                sessionStorage.setItem("supplierOpt", filterDataSupp[0].supplier);
            }
            else{
              sessionStorage.setItem("supplierOpt", "");
            }
            this.setState({
              divisionOpt: sessionStorage.getItem("divisionName") !== '' ? sessionStorage.getItem("divisionName"): null,
              supplierOpt: sessionStorage.getItem("supplierOpt") !== '' ? sessionStorage.getItem("supplierOpt") : null
          });
          }
        });
      })
      .catch((error) => {
        this.setState({
          alert: 1,
          open: true,
          loaderStore: "",
        });
      });
  };

  /**
   * @description     Function to get the selected store
   */
  handleChangeStore = (selectedOptionStore) => {
    this.setState({
      selectedOptionStore,
      storeOpt: null,
      isErrStore: false,
    });

    if (selectedOptionStore !== null) {
      this.setState({
        storeOpt: selectedOptionStore.value,
      });
    }
  };

  /**
   * @description     Function to get the selected supplier
   */
  handleChangeSupplier = (selectedOptionSupplier) => {
    sessionStorage.removeItem("supplierNumber");
    sessionStorage.removeItem("supplierOpt");
    this.setState({
      selectedOptionSupplier,
      supplierOpt: null,
    });

    if (selectedOptionSupplier !== null) {
      this.setState({
        supplierOpt: selectedOptionSupplier.value,
      });
    }
  };

  /**
   * @description     Function to get the selected store status
   */
  handleChangeStoreStatus = (selectedOptionStoreStatus) => {
    sessionStorage.removeItem("storeStatusOpt");
    this.setState({
      selectedOptionStoreStatus,
      storeStatusOpt: null,
    });

    if (selectedOptionStoreStatus !== null) {
      this.setState({
        storeStatusOpt: selectedOptionStoreStatus.value,
      });
      sessionStorage.setItem("storeStatus", selectedOptionStoreStatus.value);
      sessionStorage.setItem("storeStatusOpt", selectedOptionStoreStatus.value);
    } else {
      sessionStorage.setItem("storeStatus", "All");
    }
  };

   /**
   * @description     Function to get the selected order date
   */
    /*handleChangeOrderDate = (selectedOptionOrderDate) => {
      sessionStorage.removeItem("orderDateOpt");
      this.setState({
        selectedOptionOrderDate,
        orderDateOpt: null,
      });
  
      if (selectedOptionOrderDate !== null) {
        this.setState({
          orderDateOpt: selectedOptionOrderDate.value,
        });
        sessionStorage.setItem("orderDate", selectedOptionOrderDate.value);
        sessionStorage.setItem("orderDateOpt", selectedOptionOrderDate.value);
      } else {
        sessionStorage.setItem("orderDate", "");
      }
    };*/
    handleChangeOrderDate = (selectedOptionOrderDate) => {
      let tempOrderDateList = this.state.optionOrderDate;
      let filterData = [];
  
      sessionStorage.removeItem("orderDateFilter");
      sessionStorage.removeItem("orderDateDB");
      sessionStorage.removeItem("orderDateOpt");
  
      this.setState({
        selectedOptionOrderDate,
        orderDateOpt: null
        
      });
  
      if (selectedOptionOrderDate !== null) {
        this.setState({
          orderDateOpt: selectedOptionOrderDate && selectedOptionOrderDate.value,
        });
        if (tempOrderDateList !== undefined) {
          this.setState({
            open: false,
          });
        }
        filterData = tempOrderDateList.filter((x) => {
          return x.orderDate === selectedOptionOrderDate.value;
        });
        this.setState({
          selectedOptionOrderDateData: filterData[0],
        });
        sessionStorage.setItem("orderDateFilter", filterData[0].orderDate);
        sessionStorage.setItem("orderDateOpt", filterData[0].orderDate);
        sessionStorage.setItem("orderDateDB", filterData[0].orderDateDB);
      } else {
        sessionStorage.setItem("orderDateFilter", "");
        sessionStorage.setItem("orderDateOpt", "");
        sessionStorage.setItem("orderDateDB", "");
      }
    };
  

  handleChangeOrderTypeCode = (selectedOptionOrderCodeType) => {
    let tempOrderCodeTypeList = this.state.optionOrderCodeType;
    let filterData = [];
    

    sessionStorage.removeItem("orderTypeCodeOpt");
	sessionStorage.removeItem("code");
	sessionStorage.removeItem("type");

    this.setState({
      selectedOptionOrderCodeType,
      orderTypeCodeOpt: null
      
    });

    if (selectedOptionOrderCodeType !== null) {
      this.setState({
        orderTypeCodeOpt: selectedOptionOrderCodeType && selectedOptionOrderCodeType.value,
      });
      if (tempOrderCodeTypeList !== undefined) {
        this.setState({
          open: false,
        });
      }
      filterData = tempOrderCodeTypeList.filter((x) => {
        return x.Type === selectedOptionOrderCodeType.value;
      });
      this.setState({
        selectedOptionOrderCodeTypeData: filterData[0],
      });
      sessionStorage.setItem("code", filterData[0].Code);
	  sessionStorage.setItem("type", filterData[0].Type);
    sessionStorage.setItem("orderTypeCodeOpt", filterData[0].Type);
    } else {
      sessionStorage.setItem("code", "");
	  sessionStorage.setItem("type", "");
    sessionStorage.setItem("orderTypeCodeOpt", "");
    }
  };



  /**
   * @description     Function to get the selected DC
   */
  handleChangeDC = (selectedOptionDC) => {
    let tempDCList = this.state.optionDC;
    let filterData = [];
    sessionStorage.removeItem("distributionNumber");
    sessionStorage.removeItem("distributionName");
    sessionStorage.removeItem("dcStatusOpt");
    sessionStorage.setItem("dcStatus", "All");

    this.setState({
      selectedOptionDC,
      dcOpt: null,
      dcStatusOpt: null,
      isErrDC: false,
    });

    if (selectedOptionDC !== null) {
      this.setState({
        dcOpt: selectedOptionDC.value,
      });
      if (tempDCList !== undefined) {
        this.setState({
          open: false,
        });
      }
      filterData = tempDCList.filter(
        (x) => x.dcName === selectedOptionDC.value
      );
      if(filterData.length)
      {
        sessionStorage.setItem("distributionNumber", filterData[0].dcId);
        sessionStorage.setItem("distributionName", filterData[0].dcName);
      }
      this.setState({
        selectedOptionDCData: filterData[0],
      });
    }
  };

  /**
   * @description     Function to get the selected DC status
   */
  handleChangeDCStatus = (selectedOptionDCStatus) => {
    sessionStorage.removeItem("dcStatusOpt");
    this.setState({
      selectedOptionDCStatus,
      dcStatusOpt: null,
    });

    if (
      selectedOptionDCStatus !== null ||
      (sessionStorage.getItem("dcStatusOpt") !== null &&
        sessionStorage.getItem("dcStatusOpt") !== "")
    ) {
      this.setState({
        dcStatusOpt: selectedOptionDCStatus.value,
      });
      sessionStorage.setItem(
        "dcStatus",
        selectedOptionDCStatus !== null
          ? selectedOptionDCStatus.value
          : sessionStorage.getItem("dcStatusOpt")
      );
      sessionStorage.setItem(
        "dcStatusOpt",
        selectedOptionDCStatus !== null
          ? selectedOptionDCStatus.value
          : sessionStorage.getItem("dcStatusOpt")
      );
    } else {
      sessionStorage.setItem("dcStatus", "All");
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.alert && (
          <Snackbar
            open={this.state.open}
            autoHideDuration={1000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert onClose={this.handleClose} severity="error">
              Unable to retrieve data, please try again.
            </Alert>
          </Snackbar>
        )}
        
        {this.state.info && (
          <Snackbar
            open={this.state.open}
            autoHideDuration={1000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert  severity="error">
            <span><i><b>Access Denied</b></i></span>
            <div>
            <p className={styles.linebreak}>Your User Account’s Division <b>{sessionStorage.getItem("userDivisionId")}</b> is not permitted to view any Retail Divisions.<br></br>
            So, unfortunately there is currently no data accessible to you.</p>
            <p className={styles.linebreak}>If this is unexpected, please discuss with your manager.</p>
            <p className={styles.linebreak}>If your User Account’s Division is accurate, open a helpdesk ticket to have the mapping adjusted in Where’s My Order.<br></br>
            If your User Account’s Division needs adjusting, please open a helpdesk ticket to have that changed.</p>
            </div>
            <Button variant="outlined" color="inherit" size="small" onClick={this.handleClose} disableElevation >  Close</Button>
            </Alert>
          </Snackbar>
        )}
        <Header />
        <div className={styles.splitLeft}>
          <img
            data-testid="background-image"
            src={background.default}
            alt="background-image"
            className={styles.imageStyleBackground}
          />
        </div>

        {this.state.loaderDivision && (
          <div className="backdrop">
            <div className={styles.loaderDiv}>
              <div className="loaderHome" />
              <div className={styles.loadingMsg}>Loading...</div>
            </div>
          </div>
        )}

        {this.state.loaderStore && (
          <div className="backdrop">
            <div className={styles.loaderDiv}>
              <div className="loaderHome" />
              <div className={styles.loadingMsg}>Loading...</div>
            </div>
          </div>
        )}
        <div className={styles.splitRight}>
          <div className={styles.loginForm}>
            <label className={styles.headLabel} data-testid="headLabel">
              Track Order
            </label>
            <div className={styles.tabDiv}>
              <span
                data-testid="tabLabelStore"
                className={`tabLabel ${
                  this.state.storeTab === true ? "active" : "inActive1"
                }`}
                onClick={() => this.tabSelection("store")}
              >
                Store
              </span>
              <span
                data-testid="tabLabelDistribution"
                className={`tabLabel ${
                  this.state.storeTab === false ? "active" : "inActive1"
                }`}
                onClick={() => this.tabSelection("distribution")}
              >
                Distribution Center
              </span>
            </div>
            {this.state.storeTab && (
              <div className={styles.questionDiv}>
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelDivision"
                  >
                    Division
                  </div>
                  <div className={styles.selectWrapper} role="division-select">
                    <Select
                      value={
                        this.state.divisionOpt !== null
                          ? {
                              label: this.state.divisionOpt,
                              value: this.state.divisionOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeDivison}
                      options={this.state.optionDivision.map((item, index) => ({
                        label: item.divisionName,
                        value: item.divisionName,
                      }))}
                      className="Select-container"
                      data-testid="select-division"
                      classNamePrefix="select"
                      placeholder="Select Division"
                      isClearable
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                      styles={
                        !this.state.isErrDivision
                          ? {
                              control: (provided, state) => ({
                                ...provided,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),
                              option: (provided, state) => ({
                                ...provided,
                              }),
                            }
                          : {
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: "#ff1616",
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),

                              option: (provided, state) => ({
                                ...provided,
                              }),
                            }
                      }
                    />

                    {this.state.isErrDivision && (
                      <span className={styles.errMsg}>
                        Please select a division
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelStore"
                  >
                    Store
                  </div>
                  <div className={styles.selectWrapper}>
                    <Select
                      value={
                        this.state.divisionOpt !==null && this.state.storeOpt !== null
                          ? {
                              label: this.state.storeOpt,
                              value: this.state.storeOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeStore}
                      options={this.state.optionStore.map((item, index) => ({
                        label: item,
                        value: item,
                      }))}
                      className="Select-container"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      placeholder="Select Store"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                      styles={
                        !this.state.isErrStore
                          ? {
                              control: (provided, state) => ({
                                ...provided,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),
                            }
                          : {
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: "#ff1616",
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),
                            }
                      }
                    />
                    {this.state.isErrStore && (
                      <span className={styles.errMsg}>
                        Please select a store
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelSupplier"
                  >
                    Supplier
                  </div>
                  <div className={styles.selectWrapper}>
                    <Select
                      value={
                        this.state.supplierOpt !== null
                          ? {
                              label: this.state.supplierOpt,
                              value: this.state.supplierOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeSupplier}
                      isDisabled={
                        this.state.optionSupplier === null ? true : false
                      }
                      options={this.state.optionSupplier.map((item, index) => ({
                        label: item.supplier,
                        value: item.supplier,
                      }))}
                      className="Select-container"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      placeholder="Select Supplier"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelOrderStatus"
                  >
                    Order Status
                  </div>
                  <div className={styles.selectWrapper}>
                    <Select
                      value={
                        this.state.storeStatusOpt !== null && this.state.storeStatusOpt !== ''
                          ? {
                              label: this.state.storeStatusOpt,
                              value: this.state.storeStatusOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeStoreStatus}
                      options={this.state.optionStoreStatus.map(
                        (item, index) => ({
                          label: item.status,
                          value: item.status,
                        })
                      )}
                      className="Select-container"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      placeholder="Select Status"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                    />
                  </div>
                </div>
                {/* Added on 09/05/2022 starts here */}
              {/* Order Date starts here */}
              
              
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelOrderDate"
                  >
                    Order Date
                  </div>
                  <div className={styles.selectWrapper} role="division-select">
                    <Select
                      value={
                        this.state.orderDateOpt !== null && this.state.orderDateOpt !== ''
                          ? {
                              label: this.state.orderDateOpt,
                              value: this.state.orderDateOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeOrderDate}
                      options={this.state.optionOrderDate.map((item, index) => ({
                        label: item.orderDate,
                        value: item.orderDate,
                      }))}
                      className="Select-container"
                      data-testid="select-orderDate"
                      classNamePrefix="select"
                      placeholder="Select Order Date"
                      isClearable
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                      styles={
                        !this.state.isErrOrderDate
                          ? {
                              control: (provided, state) => ({
                                ...provided,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),
                              option: (provided, state) => ({
                                ...provided,
                              }),
                            }
                          : {
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: "#ff1616",
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                              }),

                              option: (provided, state) => ({
                                ...provided,
                              }),
                            }
                      }
                    />

                    {this.state.isErrOrderDate && (
                      <span className={styles.errMsg}>
                        Please select a Order Date
                      </span>
                    )}
                  </div>
                </div>

              {/* Order Date */}
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelOrderTypeCode"
                  >
                    Order Type
                  </div>
                  <div className={styles.selectWrapper}>
                    <Select
                      value={
                        this.state.orderTypeCodeOpt !== null && this.state.orderTypeCodeOpt !== ''
                          ? {
                              label: this.state.orderTypeCodeOpt,
                              value: this.state.orderTypeCodeOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeOrderTypeCode}
                      options={this.state.optionOrderCodeType.map(
                        (item, index) => ({
                          label: item.Type,
                          value: item.Type,
                        })
                      )}
                      className="Select-container"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      placeholder="Select Order Type"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                    />
                  </div>
                </div>
                {/* Ends here */}
              </div>
            )}
            {!this.state.storeTab && (
              <div className={styles.questionDiv}>
                <div>
                  <div
                    className={styles.questionLabel}
                    data-testid="questionLabelDC"
                  >
                    DC
                  </div>
                  <div>
                    <div className={styles.selectWrapper}>
                      <Select
                        value={
                          this.state.dcOpt !== null
                            ? {
                                label: this.state.dcOpt,
                                value: this.state.dcOpt,
                              }
                            : ""
                        }
                        onChange={this.handleChangeDC}
                        options={this.state.optionDC !==null ?
                        this.state.optionDC.map((item, index) => ({
                          label: item.dcName,
                          value: item.dcName,
                        })):""}
                        className="Select-container"
                        classNamePrefix="select"
                        isClearable
                        isSearchable={true}
                        placeholder="Select DC"
                        styles={
                          !this.state.isErrDC
                            ? {
                                control: (provided, state) => ({
                                  ...provided,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                }),
                              }
                            : {
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: "#ff1616",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                }),
                              }
                        }
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          borderColor: "white",
                          colors: {
                            ...theme.colors,
                            primary25: "#4C9AFF",
                            primary: "white",
                            neutral0: "#00367F",
                          },
                        })}
                      />
                      {this.state.isErrDC && (
                        <span className={styles.errMsg}>
                          Please select a DC
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.questionLabel}>Order Status</div>
                  <div className={styles.selectWrapper}>
                    <Select
                      classNamePrefix="list"
                      value={
                        this.state.dcStatusOpt !== null
                          ? {
                              label: this.state.dcStatusOpt,
                              value: this.state.dcStatusOpt,
                            }
                          : ""
                      }
                      onChange={this.handleChangeDCStatus}
                      options={this.state.optionDCStatus.map((item, index) => ({
                        label: item.status,
                        value: item.status,
                      }))}
                      className="Select-container"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      placeholder="Select Status"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        borderColor: "white",
                        colors: {
                          ...theme.colors,
                          primary25: "#4C9AFF",
                          primary: "white",
                          neutral0: "#00367F",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
            <button
              data-testid="button-login"
              className={styles.buttonLogin}
              onClick={() => this.trackOrder()}
            >
              Track Order
            </button>
            <br />
            &nbsp;
          </div>
        </div>
        <Helmet bodyAttributes={{ style: "background-color : #002469" }} />
      </div>
    );
  }
}
export default withRouter(HomePage);
