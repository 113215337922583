import React, { Component } from "react";
import moment from "moment";


export class OrderDetailsToPrintExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDataList: [],
            totalQty: 0,
            totalShipped: 0,
            divisionNum: "",
            divisionName: "",
            supplierNumber: "",
            supplierName: "",
            storeNumber: "",
            orderDate: "",
            orderDateDB: "",
            deliveryDate: "",
            orderType: "",
        };
    }

    componentDidMount() {
        let divisionNum = sessionStorage.getItem("divisionNumber");
        let divisionName = sessionStorage.getItem("divisionName");
        let supplierNumber = sessionStorage.getItem("supplierStoreNumber");
        let supplierName = sessionStorage.getItem("supplierName");
        let storeNumber = sessionStorage.getItem("storeNumber");
        let deliveryDate = sessionStorage.getItem("deliveryDate");
        let orderType = sessionStorage.getItem("type");
        let orderDateDB = sessionStorage.getItem("orderDateDB") !== '' ? 
                    moment(sessionStorage.getItem("orderDateDB")).format("DD-MMM-YY") : 
                    sessionStorage.getItem("orderDateDB");
        var date = moment(deliveryDate).format("MMM DD YYYY");

        let orderDateForPrint = [
            ...new Set(
                sessionStorage
                    .getItem("orderDate")
                    .split(",")
                    .map((item) => item.trim())
            ),
        ];

        let orderDate = orderDateForPrint.toString();

        this.setState({
            divisionNum: divisionNum,
            divisionName: divisionName,
            supplierNumber: supplierNumber,
            supplierName: supplierName,
            storeNumber: storeNumber,
            orderDate: orderDate,
            deliveryDate: date,
            orderType: orderType === '' ? 'All' : orderType,
            orderDateDB : orderDateDB,
        });
        this.getStoreRouteData();
    }

    /**
     * @description     Function to get the store order details
     */
    getStoreRouteData = () => {
        let totalQty = 0;
        let totalShipped = 0;

        let orderDetailData = this.props.data;

        if (orderDetailData !== undefined) {
            if (
                orderDetailData !== null &&
                orderDetailData.orderDetailsList !== undefined &&
                orderDetailData.orderDetailsList !== null &&
                orderDetailData.orderDetailsList.length > 0
            ) {
                for (let i = 0; i < orderDetailData.orderDetailsList.length; i++) {
                    totalQty =
                        totalQty +
                        parseInt(
                            orderDetailData.orderDetailsList[i].quantityOrdered !== null
                                ? orderDetailData.orderDetailsList[i].quantityOrdered
                                : 0
                        );
                }
                for (let i = 0; i < orderDetailData.orderDetailsList.length; i++) {
                    if (orderDetailData.orderDetailsList[i].shippedQuantity !== null) {
                        totalShipped =
                            totalShipped +
                            parseInt(
                                orderDetailData.orderDetailsList[i].shippedQuantity !== null
                                    ? orderDetailData.orderDetailsList[i].shippedQuantity
                                    : 0
                            );
                    }
                }

                if (isNaN(totalQty)) {
                    totalQty = 0;
                }

                if (isNaN(totalShipped)) {
                    totalShipped = 0;
                }
                this.setState({
                    orderDataList: orderDetailData.orderDetailsList,
                    totalQty: totalQty,
                    totalShipped: totalShipped,
                });
            } else {
                this.setState({
                    orderDataList: [],
                    totalQty: totalQty,
                    totalShipped: totalShipped,
                });
            }
        } else {
            this.setState({
                orderDataList: [],
                totalQty: totalQty,
                totalShipped: totalShipped,
            });
        }
    };

    /**
   * @description     Function to get the date in the DD-MMM-YY format
   */
    formatDate = (srcDate) => {
        let datePart = srcDate.match(/\d+/g),
        monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
        year = datePart[0].substring(2), // get only two digits
        month = monthNames[datePart[1] - 1], 
        day = datePart[2];
        return day+'-'+month+'-'+year;
    };

    render() {
        return (
            <div>
                <div class="tableRow">
                    <table id="table-to-xls">
                        <tr>
                            {/* <table style={{ border: 'none!important' }}> */}
                                {/* <tr style={{ border: 'none!important' }}> */}
                                    <td colspan="14"  rowSpan="2"  class="text-center" align="center">
                                        <h3 class="text-center" data-testid="printHeading">
                                            Order Details
                                        </h3>
                                    </td>
                                </tr><tr></tr>	&nbsp;&nbsp;&nbsp;
                                <tr style={{ border: 'none!important' }}>
                                    <td colspan="3"  class="text-left">Division:
                                        <span class="uppercase">
                                            <b>
                                                {" "}
                                                {this.state.divisionNum} - {this.state.divisionName}
                                            </b>
                                        </span>
                                    </td>
                                    <td colspan="3" class="text-left">Order Date:
                                        <span class="uppercase">
                                            {" "}
                                            <b> {this.state.orderDate} </b>{" "}
                                        </span>
                                    </td>
                                    {
                                        this.state.orderDateDB && (
                                            <td colspan="3" class="text-left">Order Date Filter:
                                                <span class="uppercase">
                                                    {" "}
                                                    <b> {this.state.orderDateDB} </b>{" "}
                                                </span>
                                            </td>
                                        )
                                    }
                                    {
                                        ((!this.state.orderDateDB) && this.state.orderType && this.state.orderType !== 'All') && (
                                            <td colspan="3" class="text-left">Order Type Filter:
                                                <span class="uppercase">
                                                    {" "}
                                                    <b> {this.state.orderType} </b>{" "}
                                                </span>
                                            </td>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <td colspan="3"   class="text-left">Store:
                                        <span class="uppercase">
                                            {" "}
                                            <b> {this.state.storeNumber} </b>{" "}
                                        </span>
                                    </td>
                                    <td colspan="3" class="text-left">Delivery Date:{" "}
                                        <span class="uppercase">
                                            {" "}
                                            <b>
                                                {this.state.deliveryDate.slice(3, 6)}-
                                                {this.state.deliveryDate.slice(0, 3)}-
                                                {this.state.deliveryDate.slice(9, 11)}
                                            </b>
                                        </span>{" "}
                                    </td>
                                    {
                                        (this.state.orderDateDB && this.state.orderType && this.state.orderType !== 'All') && (
                                            <td colspan="3" class="text-left">Order Type Filter:
                                                <span class="uppercase">
                                                    {" "}
                                                    <b> {this.state.orderType} </b>{" "}
                                                </span>
                                            </td>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <td colspan="3"   class="text-left">Supplier:
                                        <span class="uppercase">
                                            <b>
                                                {" "}
                                                {this.state.supplierNumber}-
                                                {this.state.supplierName &&
                                                    this.state.supplierName.trim().replace(/\0/g, "")}
                                            </b>{" "}
                                        </span>
                                    </td>
                                </tr>
                            {/* </table> */}
                        {/* </tr> */}
                        {/* <tr> */}
                            <table class="table-prop">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center" >
                                            Inv Nbr
                                        </th>
                                        <th scope="col" class="text-center">
                                            CIC
                                        </th>
                                        <th scope="col" colSpan="2" class="text-center">
                                            DSD UPC
                                        </th>
                                        <th scope="col" colSpan="2" class="text-center">
                                            New Product
                                        </th>
                                        <th scope="col" class="text-center br-width">
                                            BR Item #
                                        </th>
                                        <th scope="col" colSpan="5" class="text-center">
                                            Item Description
                                        </th>
                                        <th scope="col" class="text-center">
                                            Order Qty
                                        </th>
                                        <th scope="col" class="text-center">
                                            Qty Ship
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.orderDataList.map((value) => {
                                        return this.renderOrderDetailList(value);
                                    })}
                                </tbody>
                                <tr>
                                    <td colSpan="10" class="text-left">
                                        Total
                                    </td>
                                    <td class="text-center">{this.state.totalQty}</td>
                                    <td class="text-center">{this.state.totalShipped}</td>
                                </tr>
                            </table>

                        {/* </tr> */}
                    </table>
                </div>
                
                        
                   
            </div>
        );
    }

    renderOrderDetailList = (value) => {
        try {
            return (
                <tr key={value.invNbr}>
                    <td class="text-center">
                        {value.invoiceNumber === "0" ? " " : value.invoiceNumber}
                    </td>
                    <td class="text-center">{value.corpItemCode}</td>
                    <td colSpan="2" class="text-center">{value.upcItem}</td>
                    <td colSpan="2" class="text-center">{value.newProduct}</td>
                    <td class="text-center">{value.itemID}</td>
                    <td colSpan="5" class="text-left">{value.itemDesc}</td>
                    <td class="text-center">
                        {" "}
                        {value.quantityOrdered ? Math.trunc(value.quantityOrdered) : 0}
                    </td>
                    <td class="text-center">
                        {" "}
                        {value.shippedQuantity ? Math.trunc(value.shippedQuantity) : 0}
                    </td>
                </tr>
            );
        } catch (err) { }
    };
}
export default OrderDetailsToPrintExcel;
