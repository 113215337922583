import React, { Component } from "react";
import moment from "moment";

export class OrderExeptionToPrintExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalExpQty: 0,
      totalExpShipped: 0,
      exceptionDataList: [],
      divisionNum: "",
      divisionName: "",
      supplierNumber: "",
      supplierName: "",
      storeNumber: "",
      orderDate: "",
      deliveryDate: "",
    };
  }

  componentDidMount() {
    let divisionNum = sessionStorage.getItem("divisionNumber");
    let divisionName = sessionStorage.getItem("divisionName");
    let supplierNumber = sessionStorage.getItem("supplierStoreNumber");
    let supplierName = sessionStorage.getItem("supplierName");
    let storeNumber = sessionStorage.getItem("storeNumber");
    let deliveryDate = sessionStorage.getItem("deliveryDate");
    var date = moment(deliveryDate).format("MMM DD YYYY");

    let orderDateForPrint = [
      ...new Set(
        sessionStorage
          .getItem("orderDate")
          .split(",")
          .map((item) => item.trim())
      ),
    ];
    let orderDate = orderDateForPrint.toString();

    this.setState({
      divisionNum: divisionNum,
      divisionName: divisionName,
      supplierNumber: supplierNumber,
      supplierName: supplierName,
      storeNumber: storeNumber,
      orderDate: orderDate,
      deliveryDate: date,
    });
    this.getStoreRouteData();
  }

  /**
   * @description     Function to get the store order exeption details
   */
  getStoreRouteData = () => {
    let totalExpQty = 0;
    let totalExpShipped = 0;
    let orderExceptionData = this.props.data;

    if (orderExceptionData !== undefined) {
      if (
        orderExceptionData !== null &&
        orderExceptionData.orderExceptionList !== null &&
        orderExceptionData.orderExceptionList !== undefined &&
        orderExceptionData.orderExceptionList.length > 0
      ) {
        for (let i = 0; i < orderExceptionData.orderExceptionList.length; i++) {
          totalExpQty =
            totalExpQty +
            parseInt(
              orderExceptionData.orderExceptionList[i].quantityOrdered !== null
                ? orderExceptionData.orderExceptionList[i].quantityOrdered
                : 0
            );
        }
        for (let i = 0; i < orderExceptionData.orderExceptionList.length; i++) {
          totalExpShipped =
            totalExpShipped +
            parseInt(
              orderExceptionData.orderExceptionList[i].quantityNotShipped !==
                null
                ? orderExceptionData.orderExceptionList[i].quantityNotShipped
                : 0
            );
        }

        if (isNaN(totalExpQty)) {
          totalExpQty = 0;
        }

        if (isNaN(totalExpShipped)) {
          totalExpShipped = 0;
        }
        this.setState({
          exceptionDataList: orderExceptionData.orderExceptionList,
          totalExpQty: totalExpQty,
          totalExpShipped: totalExpShipped,
        });
      } else {
        this.setState({
          exceptionDataList: [],
          totalExpQty: totalExpQty,
          totalExpShipped: totalExpShipped,
        });
      }
    } else {
      this.setState({
        exceptionDataList: [],
        totalExpQty: totalExpQty,
        totalExpShipped: totalExpShipped,
      });
    }
  };

  render() {
    return (
        <div>
            <div class="tableRow">
                <table id="exceptiontable-to-xls">
                    <tr>
                        {/* <table style={{ border: 'none!important' }}>
                            <tr style={{ border: 'none!important' }}> */}
                                <td colspan="17" rowSpan="2" class="text-center" align="center">
                                    <h3 class="text-center" data-testid="printHeading">
                                        Order Exceptions
                                    </h3>
                                </td>
                            </tr><tr></tr>	&nbsp;&nbsp;&nbsp;
                            <tr style={{ border: 'none!important' }}>
                                <td colSpan="3"  class="text-left">Division:
                                    <span class="uppercase">
                                        <b>
                                            {" "}
                                            {this.state.divisionNum} - {this.state.divisionName}
                                        </b>
                                    </span>
                                </td>
                                <td colSpan="3"  class="text-left">Order Date:
                                    <span class="uppercase">
                                        {" "}
                                        <b> {this.state.orderDate} </b>{" "}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3"  class="text-left">Store:
                                    <span class="uppercase">
                                        {" "}
                                        <b> {this.state.storeNumber} </b>{" "}
                                    </span>
                                </td>
                                <td colSpan="3"  class="text-left">Delivery Date:{" "}
                                    <span class="uppercase">
                                        {" "}
                                        <b>
                                            {this.state.deliveryDate.slice(3, 6)}-
                                            {this.state.deliveryDate.slice(0, 3)}-
                                            {this.state.deliveryDate.slice(9, 11)}
                                        </b>
                                    </span>{" "}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" class="text-left">Supplier:
                                    <span class="uppercase">
                                        <b>
                                            {" "}
                                            {this.state.supplierNumber}-
                                            {this.state.supplierName &&
                                                this.state.supplierName.trim().replace(/\0/g, "")}
                                        </b>{" "}
                                    </span>
                                </td>
                            </tr>
                        {/* </table>
                    </tr>
                    <tr> */}
                        <table class="table-prop">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center" >
                                        Inv Nbr
                                    </th>
                                    <th scope="col" class="text-center">
                                        CIC
                                    </th>
                                    <th scope="col" colSpan="2" class="text-center">
                                        DSD UPC
                                    </th>
                                    <th scope="col" colSpan="2" class="text-center">
                                        New Product
                                    </th>
                                    <th scope="col" class="text-center br-width">
                                        BR Item #
                                    </th>
                                    <th scope="col" colSpan="5" class="text-center">
                                        Item Description
                                    </th>
                                    <th scope="col" class="text-center">
                                        Order Qty
                                    </th>
                                    <th scope="col" colSpan="2" class="text-center">
                                        Ship Qty Adjust +/-
                                    </th>
                                    <th scope="col" colSpan="5" class="text-center">
                                        Discrepancy Reason
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.exceptionDataList.map((value) => {
                                    return this.renderOrderExceptionList(value);
                                })}
                            </tbody>
                            <tr>
                                <td colSpan="10" class="text-left">
                                    Total
                                </td>
                                <td class="text-center">{this.state.totalExpQty}</td>
                                <td class="text-center">{this.state.totalExpShipped}</td>
                                <td colSpan="5"></td>
                            </tr>
                        </table>

                    {/* </tr> */}
                </table>
            </div>       
        </div>
    );
}

  renderOrderExceptionList = (value) => {
    try {
      return (
        <tr
          key={value.invNbr}
          style={{
            borderTop: value.orderedCICLines === 1 ? "2px solid black" : "",
          }}
        >
          <td class="text-center">
            {value.invoiceNumber === "0" ? " " : value.invoiceNumber}
          </td>
          <td class="text-center">
            {" "}
            {value.sub1TypCD !== null && value.sub1TypCD !== " "
              ? "*" + value.corpItemCode
              : value.corpItemCode}
          </td>
          <td colSpan="2" class="text-center">{value.upcItem}</td>
          <td colSpan="2" class="text-center">
            {value.newProduct}
          </td>
          <td class="text-center">{value.itemID}</td>
          <td colSpan="5" class="text-left">{value.itemDesc}</td>
          <td class="text-center">
            {value.quantityOrdered ? Math.trunc(value.quantityOrdered) : 0}
          </td>
          <td colSpan="2" class="text-center">
            {value.quantityNotShipped
              ? Math.trunc(value.quantityNotShipped)
              : 0}
          </td>
          <td colSpan="5" class="text-left">
            {value.altCorpItemCode > 0
              ? value.discrepencyReason + ":" + value.altCorpItemCode
              : value.discrepencyReason}
          </td>
        </tr>
      );
    } catch (err) {}
  };
}
export default OrderExeptionToPrintExcel;
