import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "../assets/css/header.module.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { authProvider } from "../util/AuthProvider";
import config from "../configurations/config";

const logo = require("../assets/images/logo.png");
const headerLine = require("../assets/images/header-line.png");
let name;

const auth = authProvider();

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null
    };
  }

  setUserName = () => {
    if (this.state.username === null && auth.name !== null) {
      this.setState({
        username: auth.name
      });
    }
  };

  componentDidMount() {
    this.setUserName();
  }

  componentDidUpdate() {
    this.setUserName();
  }

  getAccount = () => {
    if (this.state.username && this.state.username.length > 0) {
      return this.state.username;
    }
  };

  getShortName = () => {
    if (this.state.username && this.state.username.length > 0) {
      name = this.state.username;
      const fullName = name.split(" ");
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    }
  };

  logout = () => {
    sessionStorage.clear();
    window.location.replace(config.authnBaseURL + "/logout");
  };

  render() {
    return (
      <div className={styles.header}>
        <div className={styles.headerDiv}>
          <span>
            <img src={logo.default} alt="logoImg" className={styles.logoImg} />
            <img
              src={headerLine.default}
              alt="headerLineImg"
              className={styles.headerLineImg}
            />
            <span
              className={styles.logoHeadingName}
              data-testid="logoHeading"
              role="app-name"
            >
              <b>Where’s My Order?</b>                           
            </span>
          </span>
          <span className={styles.nameSec}>
            <span
              className={styles.headerNameInitial}
              data-testid="headerNameInitial"
            >
              {this.getShortName()}
            </span>
            <span>
              <div
                className={styles.headerName}
                data-testid="headerName"
                title={this.getAccount()+"[Div:"+sessionStorage.getItem("userDivisionId")+"]"}
              >
                {this.getAccount()}
              </div>
            </span>
            <span className={styles.headerLogout}>
              <a
                style={{ cursor: "pointer" }}
                data-testid="headerLogout"
                onClick={this.logout}
              >
                <ExitToAppIcon
                  aria-label="header-logout-icon"
                  className={styles.headerLogoutIcon}
                />
              </a>
            </span>
          </span>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
