import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import StoreDetail from "./components/StoreDetail";
import RouteInfoDetail from "./components/RouteInfoDetailPage";
import OrderDetailsToPrint from "./components/OrderDetailsToPrint";
import OrderExceptionToPrint from "./components/OrderExceptionToPrint";
import "./App.css";
import { authProvider } from "./util/AuthProvider";
import config from "./configurations/config";

const auth = authProvider();

export const HomeContainer = () => (
  <Switch>
    {" "}
    <Route path="/storeDetail" component={StoreDetail} />
    <Route exact path="/routeInfoDetailPage" component={RouteInfoDetail} />
    <Route exact path="/orderDetailsToPrint" component={OrderDetailsToPrint} />
    <Route
      exact
      path="/orderExeptionToPrint"
      component={OrderExceptionToPrint}
    />
    <Route path="/HomePage" component={HomePage} />
    <Route path="*" exact={true} component={HomePage} />{" "}
  </Switch>
);
export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      error: null,
      username: null,
      isAuthenticated: false
    };
  }

  clearSession() {
    sessionStorage.clear();
    sessionStorage.clear();
    window.location.replace(config.authnBaseURL + "/authenticate");
  }

  render() {
    return (
      <BrowserRouter>
        {auth.accessToken ? <HomeContainer /> : this.clearSession()}
      </BrowserRouter>
    );
  }
}
export default App;
