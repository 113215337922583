import jwt_decode from "jwt-decode";
import config from "../configurations/config";
import { FetchWrapperPost } from "./FetchWrapperPost";

export const authProvider = () => {
  let cookies = document.cookie ? document.cookie.split(";") : null;

  let accessToken;
  let decodedIdToken;
  let idToken;

  accessToken = getCookie("ent-abs-auth");
  idToken = getCookie("ent-abs-itkn");

  try {
    decodedIdToken = typeof idToken === "string" ? jwt_decode(idToken) : null;
  } catch (e) {
    decodedIdToken = null;
    sessionStorage.clear();
    sessionStorage.clear();
    window.location.replace(config.authnBaseURL + "/authenticate");
  }

  postUserData(decodedIdToken);

  return {
    accessToken: accessToken,
    idToken: idToken,
    decodedIdToken: decodedIdToken ? decodedIdToken : null,
    name: decodedIdToken ? decodedIdToken.name : null,
  };
};

function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}


function postUserData(decodedIdToken) {
  if (decodedIdToken != null && sessionStorage.getItem("userinfoStatus") != 1) {
    try {
      const body = JSON.stringify({
        companyId: decodedIdToken.swyCompany !== undefined ? decodedIdToken.swyCompany : null,
        companyName: decodedIdToken.swyComapnyName !== undefined ? decodedIdToken.swyComapnyName : null,
        corpId: decodedIdToken.swyCorporation !== undefined ? decodedIdToken.swyCorporation : null,
        deptId: decodedIdToken.departmentNumber !== undefined ? decodedIdToken.departmentNumber : null,
        deptName: decodedIdToken.swyDepartmentName !== undefined ? decodedIdToken.swyDepartmentName : null,
        deviceIndicator: "web",
        district: decodedIdToken.swyDistrict !== undefined ? decodedIdToken.swyDistrict : null,
        divisionId: decodedIdToken.swyDivision !== undefined ? decodedIdToken.swyDivision : null,
        location: decodedIdToken.swyLocation !== undefined ? decodedIdToken.swyLocation : null,
        storeId: decodedIdToken.swyFacilityCode !== undefined ? decodedIdToken.swyFacilityCode : null,
        storeName: decodedIdToken.swyFacility !== undefined ? decodedIdToken.swyFacility : null,
        userId: decodedIdToken.onpremisessamaccountname !== undefined ? decodedIdToken.onpremisessamaccountname : null,
        userTitle: decodedIdToken.Title !== undefined ? decodedIdToken.Title : null
      })
      FetchWrapperPost("/user/logininfo", "POST", body);
      sessionStorage.setItem("userinfoStatus", 1);
    } catch (error) {
      sessionStorage.setItem("userinfoStatus", 0);
    }
  }
  if(decodedIdToken != null)
  {
   const userDivId = decodedIdToken.swyDivision !== undefined ? ('00'+decodedIdToken.swyDivision).slice(-2) : '00';
	console.log("userDiv-"+decodedIdToken.swyDivision)
	console.log("userPreferred_username-"+decodedIdToken.preferred_username)
        sessionStorage.setItem("userId", decodedIdToken.onpremisessamaccountname !== undefined ? decodedIdToken.onpremisessamaccountname : '');
  	sessionStorage.setItem("userDivisionId", userDivId);
	sessionStorage.setItem("username", decodedIdToken.preferred_username !== undefined ? decodedIdToken.preferred_username : '');
  }

}
