import React, { Component } from "react";
import styles from "../assets/css/routeInfoDetail.module.css";
import { Header } from "./Header";
import OrderDetailsToPrint from "./OrderDetailsToPrint";
import OrderDetailsToPrintExcel from "./OrderDetailsToPrintExcel";
import OrderExceptionToPrint from "./OrderExceptionToPrint";
import OrderExeptionToPrintExcel from "./OrderExceptionToPrintExcel";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { FetchWrapper } from "../util/FetchWrapper";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import * as XLSX from "xlsx";

const backButton = require("../assets/images/back-button.png");
const printImg = require("../assets/images/print.png");
const ellipse = require("../assets/images/ellipse.png");
const refresh = require("../assets/images/refresh.png");
const close = require("../assets/images/close.png");
const truck = require("../assets/images/truck.png");
const emptyImg = require("../assets/images/emptyImg.png");
const exportImg = require("../assets/images/ExportExcel.png");
var last = "0";
let vehicleInfoStore = {};

export class RouteInfoDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status_selected: [],
      storeRouteInfoList: [],
      dataList: [],
      orderDataListPrint: [],
      orderDataList: [],
      exceptionDataListPrint: [],
      exceptionDataList: [],
      tabSelected: "",
      tabClicked: "RouteInfo",
      storeRoute: "",
      dcRoute: "",
      dcTimeZone: "",
      storeNumber: "",
      supplierName: "",
      supplierStoreNumber: "",
      totalQty: 0,
      totalShipped: 0,
      totalExpQty: 0,
      totalExpShipped: 0,
      showRoute: "0",
      showException: "1",
      storeInfoClicked: "",
      bounds: null,
      loaderStore: true,
      loaderDc: "",
      loaderOrderDetails: true,
      loaderExceptionDetails: true,
      headLoad: "",
      show: false,
      latitude: "",
      longitude: "",
      showLocateTruck: false,
      noRouteInfoDetails: false,
    };
  }

  componentDidMount() {
    sessionStorage.setItem("home", false);
    let tabSelected = sessionStorage.getItem("tabSelected");
    let storeRoute = sessionStorage.getItem("storeRoute");
    let storeDcNumber = sessionStorage.getItem("storeDcNumber");
    let storeTrips = sessionStorage.getItem("storeTrips");
    let dcRoute = sessionStorage.getItem("dcRoute");
    let dcNumber = sessionStorage.getItem("dcNumber");
    let dcTrips = sessionStorage.getItem("dcTrips");
    let orderHeaderNumber = sessionStorage.getItem("orderHeaderNumber");
    let showLocateTruck = sessionStorage.getItem("showLocateTruck");
    let supplierName = sessionStorage.getItem("supplierName");
    let supplierStoreNumber = sessionStorage.getItem("supplierStoreNumber");
    let storeNum = sessionStorage.getItem("storeNumber");
    let showRoute = sessionStorage.getItem("showRoute");
    let showException = sessionStorage.getItem("showException");
    let tabClicked = "";

    if (showRoute === "0") {
      tabClicked = "OrderDetails";
    } else {
      tabClicked = "RouteInfo";
    }
    if (tabSelected === "store") {
      if (showLocateTruck === "true") {
        this.getStoreRouteData(storeRoute, storeDcNumber, storeTrips);
        this.setState({
          showLocateTruck: showLocateTruck,
        });
      } else {
        this.getStoreDetailsExceptionData(orderHeaderNumber);
      }
    } else {
      this.getDCRouteData(dcRoute, dcNumber, dcTrips);
    }
    this.setState({
      tabSelected: tabSelected,
      dcRoute: dcRoute,
      storeRoute: storeRoute,
      storeNumber: storeNum,
      supplierName: supplierName,
      supplierStoreNumber: supplierStoreNumber,
      showRoute: showRoute,
      showException: showException,
      tabClicked: tabClicked,
      mapListMiddle: [{ lat: null, lng: null }],
    });
  }
  /**
   * @description     Function to get the route data for store
   */

  tryAgain = () => {
    this.props.history.push("/storeDetail");
  };

  getStoreRouteData = async (storeRoute, storeDcNumber, storeTrips) => {
    let mapData = [];
    let mapDataMiddle = [];

    this.setState({
      loaderStore: true,
    });
    FetchWrapper(
      "/store/apicRoute/" +
        storeRoute +
        "/distributionCentre/" +
        storeDcNumber +
        "/trips/" +
        storeTrips,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        let storeRouteData = [];
		let dcTmZnNm ="";
        var counter = 0;
        var mod = 1;
        var stopId = 0;
        this.setState({
          headLoad: 1,
        });

        if (data !== undefined) {
		  dcTmZnNm = data.storeRouteInfoResponses[0].dcTimeZoneName;
          for (let i = 0; i < data.storeRouteInfoResponses.length; i++) {
            counter = counter + 1;
            if (counter % 2 === 0) {
              mod = 0;
            } else {
              mod = 1;
            }
            vehicleInfoStore = data.storeRouteInfoResponses[i].vehicleInfo;
            for (
              let j = 0;
              j < data.storeRouteInfoResponses[i].orderRouteInfoList.length;
              j++
            ) {
              storeRouteData.push({
                ...data.storeRouteInfoResponses[i].orderRouteInfoList[j],
                stopID: stopId.toString(),
                mode: mod,
              });
              if (
                data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                  .latitude !== "0.00" &&
                data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                  .longitude !== "0.00"
              ) {
                const containsKey = mapData.find((dat) => {
                  if (
                    dat.key ===
                    data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude +
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .longitude
                  ) {
                    return {
                      status: true,
                      actualArrivalTime: dat.actualArrivalTime,
                      actualDepartureTime: dat.actualDepartureTime,
                    };
                  }
                });

                if (containsKey) {
                  mapData.push({
                    lat: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude,
                    lng: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .longitude,
                    storeId:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storeID,
                    storePhone:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storePhone,
                    actualArrivalTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    actualArrivalTimeLabel: containsKey.actualArrivalTime,
                    actualDepartureTimeLabel: containsKey.actualDepartureTime,
                    stopId: stopId.toString(),
                  });
                } else {
                  mapData.push({
                    lat: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude,
                    lng: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .longitude,
                    storeId:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storeID,
                    storePhone:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storePhone,
                    actualArrivalTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    key:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .latitude +
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .longitude,
                    actualArrivalTimeLabel:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTimeLabel:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    stopId: stopId.toString(),
                  });
                }
              }
              stopId = stopId + 1;
            }
          }
          if (stopId !== 0) {
            last = stopId - 1;
          }

          for (let i = 0; i < mapData.length; i++) {
            for (let j = 0; j < mapData.length; j++) {
              if (i != j) {
                if (
                  mapData[i].lat + mapData[i].lng ===
                  mapData[j].lat + mapData[j].lng
                ) {
                  mapData[i].stopId =
                    mapData[i].stopId + "," + mapData[j].stopId;
                }
              }
            }
          }
          let mapDataWithMarkers = [];
          mapData.map((data) => {
            const set = new Set(data.stopId.split(","));
            const ids = [...set].sort((num1, num2) => num1 - num2);
            const stopId = ids.join(",");
            mapDataWithMarkers.push({
              ...data,
              stopId,
            });
          });

          const bounds = new this.props.google.maps.LatLngBounds();
          for (let i = 1; i < mapData.length - 1; i++) {
            bounds.extend({
              lat: parseInt(mapData[i].lat),
              lng: parseInt(mapData[i].lng),
            });
          }
          this.setState({ bounds: bounds });
          if (mapData.length > 0) {
            var middle = mapData[Math.round((mapData.length - 1) / 2)];
            mapDataMiddle.push({
              lat: middle.lat,
              lng: middle.lng,
            });
          } else {
            mapDataMiddle.push({
              lat: "47.2758331298828",
              lng: "-122.236717224121",
            });
          }

          if (data !== null) {
            this.setState({
              dataList: storeRouteData,
              dcTimeZone: dcTmZnNm,
              mapList: mapDataWithMarkers,
              mapListMiddle: mapDataMiddle,
              loaderStore: false,
            });
          } else {
            this.setState({
              dataList: [],
              dcTimeZone: "",
              mapList: [
                { lat: null, lng: null },
                { lat: null, lng: null },
              ],
              loaderStore: false,
            });
          }
        } else {
          this.setState({
            dataList: [],
            dcTimeZone: "",
            mapList: [
              { lat: null, lng: null },
              { lat: null, lng: null },
            ],
            loaderStore: false,
            noRouteInfoDetails: true,
          });
        }
        if (this.state.loaderStore === false) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          dataList: [],
          dcTimeZone: "",
          mapList: [
            { lat: null, lng: null },
            { lat: null, lng: null },
          ],
          loaderStore: false,
          noRouteInfoDetails: true,
        });

        if (
          this.state.loaderStore === false &&
          this.state.loaderOrderDetails === false &&
          this.state.loaderExceptionDetails === false
        ) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      });
  };
  /**
   * @description     Function to get the order details and order exception details for store
   */
  getStoreDetailsExceptionData = async (orderHeaderNumber) => {
    let totalQty = 0;
    let totalShipped = 0;
    let totalExpQty = 0;
    let totalExpShipped = 0;
    this.setState({
      loaderOrderDetails: true,
      loaderExceptionDetails: true,
    });

    FetchWrapper("/store/orderdetails/" + orderHeaderNumber, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let orderDetailData = responseJson;
        this.setState({
          headLoad: 1,
        });

        if (orderDetailData !== undefined) {
          if (
            orderDetailData !== null &&
            orderDetailData.orderDetailsList !== null
          ) {
            for (let i = 0; i < orderDetailData.orderDetailsList.length; i++) {
              totalQty =
                totalQty +
                parseInt(
                  orderDetailData.orderDetailsList[i].quantityOrdered !== null
                    ? orderDetailData.orderDetailsList[i].quantityOrdered
                    : 0
                );
            }
            for (let i = 0; i < orderDetailData.orderDetailsList.length; i++) {
              if (
                orderDetailData.orderDetailsList[i].shippedQuantity !== null
              ) {
                totalShipped =
                  totalShipped +
                  parseInt(
                    orderDetailData.orderDetailsList[i].shippedQuantity !== null
                      ? orderDetailData.orderDetailsList[i].shippedQuantity
                      : 0
                  );
              }
            }

            if (isNaN(totalQty)) {
              totalQty = 0;
            }
            if (isNaN(totalShipped)) {
              totalShipped = 0;
            }
            this.setState({
              orderDataListPrint: orderDetailData,
              orderDataList: orderDetailData.orderDetailsList,
              totalQty: totalQty,
              totalShipped: totalShipped,
              loaderOrderDetails: false,
            });
          } else {
            this.setState({
              orderDataListPrint: [],
              orderDataList: [],
              totalQty: totalQty,
              totalShipped: totalShipped,
              loaderOrderDetails: false,
            });
          }
        } else {
          this.setState({
            orderDataListPrint: [],
            orderDataList: [],
            totalQty: totalQty,
            totalShipped: totalShipped,
            loaderOrderDetails: false,
          });
        }
        if (
          this.state.loaderOrderDetails === false &&
          this.state.loaderExceptionDetails === false
        ) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (
          this.state.loaderOrderDetails === false &&
          this.state.loaderExceptionDetails === false
        ) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      });

    FetchWrapper("/store/" + orderHeaderNumber, "GET")
      .then((response) => response.json())
      .then((responseJson) => {
        let orderExceptionData = responseJson;
        this.setState({
          headLoad: 1,
        });

        if (orderExceptionData !== undefined) {
          if (
            orderExceptionData !== null &&
            orderExceptionData.orderExceptionList !== null
          ) {
            for (
              let i = 0;
              i < orderExceptionData.orderExceptionList.length;
              i++
            ) {
              totalExpQty =
                totalExpQty +
                parseInt(
                  orderExceptionData.orderExceptionList[i].quantityOrdered !==
                    null
                    ? orderExceptionData.orderExceptionList[i].quantityOrdered
                    : 0
                );
            }
            for (
              let i = 0;
              i < orderExceptionData.orderExceptionList.length;
              i++
            ) {
              totalExpShipped =
                totalExpShipped +
                parseInt(
                  orderExceptionData.orderExceptionList[i]
                    .quantityNotShipped !== null
                    ? orderExceptionData.orderExceptionList[i]
                        .quantityNotShipped
                    : 0
                );
            }

            if (isNaN(totalExpQty)) {
              totalExpQty = 0;
            }

            if (isNaN(totalExpShipped)) {
              totalExpShipped = 0;
            }
            this.setState({
              exceptionDataListPrint: orderExceptionData,
              exceptionDataList: orderExceptionData.orderExceptionList,
              totalExpQty: totalExpQty,
              totalExpShipped: totalExpShipped,
              loaderExceptionDetails: false,
            });
          } else {
            this.setState({
              exceptionDataListPrint: [],
              exceptionDataList: [],
              totalExpQty: totalExpQty,
              totalExpShipped: totalExpShipped,
              loaderExceptionDetails: false,
            });
          }
        } else {
          this.setState({
            exceptionDataListPrint: [],
            exceptionDataList: [],
            totalExpQty: totalExpQty,
            totalExpShipped: totalExpShipped,
            loaderExceptionDetails: false,
          });
        }
        if (
          this.state.loaderOrderDetails === false &&
          this.state.loaderExceptionDetails === false
        ) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (
          this.state.loaderOrderDetails === false &&
          this.state.loaderExceptionDetails === false
        ) {
          this.setState({
            show: true,
          });
        } else {
          this.setState({
            show: false,
          });
        }
      });
  };

  /**
   * @description     Function to get the route data for DC
   */
  getDCRouteData = async (dcRoute, dcNumber, dcTrips) => {
    let mapData = [];
    let mapDataMiddle = [];

    this.setState({
      loaderDc: 1,
    });

    FetchWrapper(
      "/store/apicRoute/" +
        dcRoute +
        "/distributionCentre/" +
        dcNumber +
        "/trips/" +
        dcTrips,
      "GET"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        let dcTmZnNm ="";
        let dcRouteData = [];
        var counter = 0;
        var mod = 1;
        var stopId = 0;
        this.setState({
          headLoad: 1,
        });
        if (data !== undefined) {
		 dcTmZnNm = data.storeRouteInfoResponses[0].dcTimeZoneName;
          for (let i = 0; i < data.storeRouteInfoResponses.length; i++) {
            counter = counter + 1;
            if (counter % 2 === 0) {
              mod = 0;
            } else {
              mod = 1;
            }
            vehicleInfoStore = data.storeRouteInfoResponses[i].vehicleInfo;
            for (
              let j = 0;
              j < data.storeRouteInfoResponses[i].orderRouteInfoList.length;
              j++
            ) {
              dcRouteData.push({
                ...data.storeRouteInfoResponses[i].orderRouteInfoList[j],
                mode: mod,
                stopID: stopId.toString(),
              });

              if (
                data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                  .latitude !== "0.00" &&
                data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                  .longitude !== "0.00"
              ) {
                const containsKey = mapData.find((dat) => {
                  if (
                    dat.key ===
                    data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude +
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .longitude
                  ) {
                    return {
                      status: true,
                      actualArrivalTime: dat.actualArrivalTime,
                      actualDepartureTime: dat.actualDepartureTime,
                    };
                  }
                });

                if (containsKey) {
                  mapData.push({
                    lat: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude,
                    lng: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .longitude,
                    storeId:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storeID,
                    storePhone:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storePhone,
                    actualArrivalTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    actualArrivalTimeLabel: containsKey.actualArrivalTime,
                    actualDepartureTimeLabel: containsKey.actualDepartureTime,
                    stopId: stopId.toString(),
                  });
                } else {
                  mapData.push({
                    lat: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .latitude,
                    lng: data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                      .longitude,
                    storeId:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storeID,
                    storePhone:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .storePhone,
                    actualArrivalTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTime:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    key:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .latitude +
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .longitude,
                    actualArrivalTimeLabel:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualArrivalTime,
                    actualDepartureTimeLabel:
                      data.storeRouteInfoResponses[i].orderRouteInfoList[j]
                        .actualDepartureTime,
                    stopId: stopId.toString(),
                  });
                }
              }
              stopId = stopId + 1;
            }
          }

          if (stopId !== 0) {
            last = stopId - 1;
          }

          for (let i = 0; i < mapData.length; i++) {
            for (let j = 0; j < mapData.length; j++) {
              if (i != j) {
                if (
                  mapData[i].lat + mapData[i].lng ===
                  mapData[j].lat + mapData[j].lng
                ) {
                  mapData[i].stopId =
                    mapData[i].stopId + "," + mapData[j].stopId;
                }
              }
            }
          }
          let mapDataWithMarkers = [];
          mapData.map((data) => {
            const set = new Set(data.stopId.split(","));
            const ids = [...set].sort((num1, num2) => num1 - num2);
            const stopId = ids.join(",");
            mapDataWithMarkers.push({
              ...data,
              stopId,
            });
          });

          const bounds = new this.props.google.maps.LatLngBounds();
          for (let i = 1; i < mapData.length - 1; i++) {
            bounds.extend({
              lat: parseInt(mapData[i].lat),
              lng: parseInt(mapData[i].lng),
            });
          }
          this.setState({ bounds: bounds });

          if (mapData.length > 0) {
            var middle = mapData[Math.round((mapData.length - 1) / 2)];
            mapDataMiddle.push({ lat: middle.lat, lng: middle.lng });
          } else {
            mapDataMiddle.push({
              lat: "47.2758331298828",
              lng: "-122.236717224121",
            });
          }

          if (data !== null) {
            this.setState({
              dataList: dcRouteData,
              dcTimeZone: dcTmZnNm,
              mapList: mapDataWithMarkers,
              mapListMiddle: mapDataMiddle,
              loaderDc: "",
            });
          } else {
            this.setState({
              dataList: [],
              dcTimeZone: "",
              mapList: [
                { lat: null, lng: null },
                { lat: null, lng: null },
              ],
              loaderDc: "",
            });
          }
        } else {
          this.setState({
            dataList: [],
            dcTimeZone:"",
            mapList: [
              { lat: null, lng: null },
              { lat: null, lng: null },
            ],
            loaderDc: "",
            noRouteInfoDetails: true,
          });
        }
        this.setState({
          show: true,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          show: true,
        });
      });
  };

  /**
   * @description     Function to navigate to home page
   */
  homeClick = () => {
    this.props.history.push("/");
  };


  /**
   * @description     Tab Selection for Route, Order Details & Order Exceptions
   */
  tabSelection = (clicked) => {
    this.setState({
      tabClicked: clicked,
      storeInfoClicked: "",
      latitude: "",
      longitude: "",
    });
  };

  /**
   * @description     Function to navigate to store detail list page
   */
  backStoreDetail = () => {
    this.props.history.push("/storeDetail");
  };

  refresh = () => {
    document.getElementById("refresh").style.opacity = "0.5";
    window.location.reload();
  };

  exportToExcelOrderDetail = (fileName, Id) => {
    // let fileName = 'OrderDetails_ExcelSheet.xlsx'
    let element = document.getElementById(Id);
    const wrkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    const wrkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wrkBook, wrkSheet, "Sheet1");
    XLSX.writeFile(wrkBook, fileName);
  };

  handleStopInfoClick = (index, latitude, longitude) => {
    for (let i = 0; i < this.state.dataList.length; i++) {
      document.getElementById("markerRowA" + i).style.backgroundColor = "white";
      document.getElementById("markerRowB" + i).style.backgroundColor = "white";
    }
    document.getElementById("markerRowA" + index).style.backgroundColor =
      "#e1f7ff";
    document.getElementById("markerRowB" + index).style.backgroundColor =
      "#e1f7ff";
    let indexSelected = index.toString();
    this.setState({
      storeInfoClicked: indexSelected,
      latitude: latitude,
      longitude: longitude,
    });
  };

  render() {
    return (
      <div>
        <Header />
        <HomeOutlinedIcon
          aria-label="home-icon"
          className={styles.homeImg}
          onClick={() => this.homeClick()}
        />
        {!this.state.show && (
          <div className="">
            <div className={styles.loadingDiv}>
              <div className="loader" />
              <div className={styles.loadingMsg}>Loading...</div>
            </div>
          </div>
        )}
        {this.state.show && (
          <div>
            <div className={styles.pageHeader}>
              {this.state.headLoad === 1 && (
                <div className={styles.headerDiv} data-testid="backButtonDiv">
                  <span>
                    {this.state.tabSelected === "store" && (
                      <span
                        className={styles.routeHeading}
                        data-testid="routeHeadingDiv"
                      >
                        <img
                          alt="backButtonImg"
                          src={backButton.default}
                          role="back-button"
                          className={styles.imageStyleBackButton}
                          onClick={() => this.backStoreDetail()}
                        />
                        {this.state.showLocateTruck && (
                          <span>
                            {" "}
                            Route &nbsp;
                            {this.state.storeRoute !== "null" &&
                              this.state.storeRoute}
                          </span>
                        )}
                      </span>
                    )}
                    {this.state.tabSelected === "distribution" && (
                      <span
                        className={styles.routeHeadingDistribution}
                        data-testid="routeHeadingDistribution"
                      >
                        <img
                          src={backButton.default}
                          alt="backButtonDistribution"
                          role="backButtonDistribution"
                          className={styles.imageStyleBackButton}
                          onClick={() => this.backStoreDetail()}
                        />
                        {this.state.tabClicked === "RouteInfo" && (
                          <span data-testid="dcRoute">
                            Route {this.state.dcRoute}
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                  {this.state.tabSelected === "store" && (
                    <span className={styles.nameSec}>
                      {this.state.orderDataList.length > 0 && (
                        <span
                          className={`headerName ${
                            this.state.tabClicked === "OrderDetails"
                              ? "activeMapTab"
                              : "inActiveMapTab"
                          }`}
                          onClick={() => this.tabSelection("OrderDetails")}
                        >
                          Order Details
                        </span>
                      )}
                      {this.state.showException === "1" &&
                        this.state.exceptionDataList.length > 0 && (
                          <span
                            className={`headerName ${
                              this.state.tabClicked === "OrderExpectations"
                                ? "activeMapTab"
                                : "inActiveMapTab"
                            }`}
                            onClick={() =>
                              this.tabSelection("OrderExpectations")
                            }
                          >
                            Order Exceptions
                          </span>
                        )}
                    </span>
                  )}
                  <span>
                    <span>&nbsp;</span>
                  </span>
                </div>
              )}
            </div>
            {this.state.tabClicked === "RouteInfo" &&
              this.state.noRouteInfoDetails === false && (
                <div>
                  {/* {!this.state.show && ( */}
                  <div>
                    <div className={styles.splitLeft}>
                      <div className={styles.mapDiv}>
                        {this.state.mapList && (
                          <Map
                            google={this.props.google}
                            className={styles.mapInsideDiv}
                            initialCenter={
                              this.state.mapListMiddle[0] && {
                                lat: this.state.mapListMiddle[0].lat,
                                lng: this.state.mapListMiddle[0].lng,
                              }
                            }
                            zoom={8}
                            bounds={this.state.bounds}
                          >
                            {this.state.mapList.map((value, index) => {
                              return this.renderMapList(value, index);
                            })}
                            {vehicleInfoStore && (
                              <Marker
                                position={{
                                  lat: vehicleInfoStore.vehicleLatitude,
                                  lng: vehicleInfoStore.vehicleLongitude,
                                }}
                                icon={{
                                  url: truck.default,
                                }}
                              />
                            )}
                          </Map>
                        )}
                      </div>
                    </div>
                    <div className={styles.splitRight}>
                      <div className={styles.detailForm}>
                        <div className="scrollableRouteTable">
                          <table>
                            <thead>
                              <tr className={styles.routeHead}>
                                <th
                                  className={styles.routeTH}
                                  data-testid="stops"
                                >
                                  Stop #
                                </th>
                                <th
                                  className={styles.routeTH}
                                  data-testid="store-info"
                                >
                                  Store Info
                                </th>
                                <th
                                  className={styles.routeTH}
                                  data-testid="status"
                                >
                                  Status
                                  <br/>
				  					<i className={styles.tmZnTxt}>Timing as {this.state.dcTimeZone} TZ</i>
                                </th>
                              </tr>
                              <tr className={styles.headLine}></tr>
                            </thead>
                            <tbody>
                              {this.state.dataList.map((value, index) => {
                                return this.renderDetailList(value, index);
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className={styles.refreshDiv}>
                        <img
                          src={ellipse.default}
                          alt=""
                          className={styles.ellipseImg}
                        />
                        <img
                          alt="refreshImg"
                          src={refresh.default}
                          id="refresh"
                          className={styles.refreshImg}
                          onClick={() => this.refresh()}
                        />
                      </div>

                      {this.state.tabSelected === "store" && (
                        <div className={styles.routeBottomDiv}>
                          <span className={styles.routeBottomHeading}>
                            <span>
                              Route &nbsp;
                              {this.state.storeRoute !== "null" &&
                                this.state.storeRoute}
                            </span>
                          </span>
                          <span className={styles.routeBottomClose}>
                            <img
                              src={close.default}
                              alt="backStoreButtonImg"
                              className={styles.imageStyleBackButton}
                              onClick={() => this.backStoreDetail()}
                            />
                            Close
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

            {this.state.tabClicked === "RouteInfo" &&
              this.state.noRouteInfoDetails === true && (
                <div className={styles.emptyDiv}>
                  <img
                    src={emptyImg.default}
                    alt="imageStoreStyleEmpty"
                    className={styles.imageStyleEmpty}
                  />
                  <div className={styles.emptyMsg}>No Routes Available</div>
                  <div className={styles.emptyMsgDesc}>
                    <div>
                      There are no routes available for the DC that you have
                      selected
                    </div>
                  </div>
                  <div>
                    <button
                      className={styles.buttonTryAgain}
                      onClick={() => this.tryAgain("store")}
                    >
                      Select Again
                    </button>
                  </div>
                </div>
              )}

            {this.state.tabClicked === "OrderDetails" && (
              <div>
                {this.state.loaderOrderDetails && (
                  <div className={styles.loadingDiv}>
                    <div className="loader" />
                    <div className={styles.loadingMsg}>Loading...</div>
                  </div>
                )}
                {!this.state.loaderOrderDetails && (
                  <div>
                    <div className={styles.orderDetailsHeadDiv}>
                      <span>
                        <span className={styles.nameSecFirst}>
                          <div className={styles.nameSecHead}>
                            Supplier Name
                          </div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.supplierStoreNumber}-
                            {this.state.supplierName.trim().replace(/\0/g, "")}
                          </div>
                        </span>

                        <span className={styles.nameSecSecond}>
                          <div className={styles.nameSecHead}>Store</div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.storeNumber}
                          </div>
                        </span>
                      </span>

                      <span className={styles.printDiv}>
                        <span className={styles.nameSecFirst}>
                          <div
                            onClick={() =>
                              this.exportToExcelOrderDetail(
                                "OrderDetails.xlsx",
                                "table-to-xls"
                              )
                            }
                          >
                            <img
                              src={exportImg.default}
                              alt=""
                              className={styles.imageStylePrintButton}
                            />
                            <span style={{ color: "#00529f" }}> Excel </span>
                          </div>
                          <div style={{ display: "none" }}>
                            <OrderDetailsToPrintExcel
                              ref={(e2) => (this.componentRef = e2)}
                              data={this.state.orderDataListPrint}
                            />
                          </div>
                        </span>
                        <span className={styles.nameSecSecond}>
                          <ReactToPrint
                            documentTitle="wmo"
                            trigger={() => {
                              return (
                                <div>
                                  <img
                                    src={printImg.default}
                                    alt=""
                                    className={styles.imageStylePrintButton}
                                  />
                                  <span style={{ color: "#00529f" }}>
                                    {" "}
                                    Print{" "}
                                  </span>
                                </div>
                              );
                            }}
                            content={() => this.componentRef}
                          />
                          <div style={{ display: "none" }}>
                            <OrderDetailsToPrint
                              ref={(el) => (this.componentRef = el)}
                              data={this.state.orderDataListPrint}
                            />
                          </div>
                        </span>
                      </span>
                    </div>

                    <div className={styles.orderDetailsTableDiv}>
                      <div className="scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Inv <br /> Nbr
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Ordered On
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                CIC #
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                DSD UPC
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                New <br /> Product
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                BR Item #
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Item Description
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Qty Ordered
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Qty Shipped
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.orderDataList.map((value) => {
                              return this.renderOrderDetailList(value);
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className={styles.orderDetailsBottomDiv}>
                      <span className={styles.printLabel}>Total</span>
                      <span>
                        <span className={styles.nameQty}>
                          <div className={styles.nameSecHead}>Qty Ordered</div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.totalQty}
                          </div>
                        </span>

                        <span className={styles.nameQtyShipped}>
                          <div className={styles.nameSecHead}>Qty Shipped</div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.totalShipped}
                          </div>
                        </span>
                      </span>
                    </div>

                    <div className={styles.orderDetailsBottomCloseDiv}>
                      <span className={styles.routeBottomHeading}></span>
                      <span>
                        <span className={styles.nameSecFirst}>
                          <span className={styles.routeBottomClose}>
                            <ReactToPrint
                              trigger={() => {
                                return (
                                  <div>
                                    <img
                                      src={printImg.default}
                                      alt=""
                                      className={styles.imageStyleBackButton}
                                    />
                                    <span style={{ color: "#00529f" }}>
                                      {" "}
                                      Print{" "}
                                    </span>
                                  </div>
                                );
                              }}
                              content={() => this.componentRef}
                            />
                            <div style={{ display: "none" }}>
                              <OrderDetailsToPrint
                                ref={(el) => (this.componentRef = el)}
                                data={this.state.orderDataListPrint}
                              />
                            </div>
                          </span>
                        </span>
                        <span className={styles.nameSecSecond}>
                          <span className={styles.routeBottomClose}>
                            <img
                              src={close.default}
                              alt="routeBackButtonImg"
                              className={styles.imageStyleBackButton}
                              onClick={() => this.backStoreDetail()}
                            />
                            Close
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.tabClicked === "OrderExpectations" && (
              <div>
                {this.state.loaderExceptionDetails && (
                  <div className={styles.loadingDiv}>
                    <div className="loader" />
                    <div className={styles.loadingMsg}>Loading...</div>
                  </div>
                )}
                {!this.state.loaderExceptionDetails && (
                  <div>
                    <div className={styles.orderExpectationsHeadDiv}>
                      <span>
                        <span className={styles.nameSecFirst}>
                          <div className={styles.nameSecHead}>
                            Supplier Name
                          </div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.supplierStoreNumber}-
                            {this.state.supplierName.trim().replace(/\0/g, "")}
                          </div>
                        </span>

                        <span className={styles.nameSecSecond}>
                          <div className={styles.nameSecHead}>Store</div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.storeNumber}
                          </div>
                        </span>
                      </span>

                      <span className={styles.printDiv}>
                        <span className={styles.nameSecFirst}>
                          <div
                            onClick={() =>
                              this.exportToExcelOrderDetail(
                                "OrderExceptions.xlsx",
                                "exceptiontable-to-xls"
                              )
                            }
                          >
                            <img
                              src={exportImg.default}
                              alt=""
                              className={styles.imageStylePrintButton}
                            />
                            <span style={{ color: "#00529f" }}> Excel </span>
                          </div>
                          <div style={{ display: "none" }}>
                            <OrderExeptionToPrintExcel
                              ref={(el) => (this.componentExeptionRef = el)}
                              data={this.state.exceptionDataListPrint}
                            />
                          </div>
                        </span>
                        <span className={styles.nameSecSecond}>
                          <ReactToPrint
                            trigger={() => {
                              return (
                                <div>
                                  <img
                                    src={printImg.default}
                                    alt=""
                                    className={styles.imageStylePrintButton}
                                  />
                                  <span style={{ color: "#00529f" }}>
                                    {" "}
                                    Print{" "}
                                  </span>
                                </div>
                              );
                            }}
                            content={() => this.componentExeptionRef}
                          />
                          <div style={{ display: "none" }}>
                            <OrderExceptionToPrint
                              ref={(el) => (this.componentExeptionRef = el)}
                              data={this.state.exceptionDataListPrint}
                            />
                          </div>
                        </span>
                      </span>
                    </div>

                    <div className={styles.orderExpectationsTableDiv}>
                      <div className="scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Inv <br />
                                Nbr
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Ordered On
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                CIC #
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                DSD UPC
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                New <br /> Product
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                BR Item #
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Item Description
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Qty Ordered
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Ship Qty <br />
                                Adjust +/-
                              </th>
                              <th style={{ backgroundColor: "#F4F8FA" }}>
                                Discrepancy Reason
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.exceptionDataList.map((value) => {
                              return this.renderOrderExceptionList(value);
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className={styles.orderExpectationsBottomDiv}>
                      <span className={styles.printLabel}>Total</span>
                      <span>
                        <span className={styles.nameQtyOrdered}>
                          <div className={styles.nameSecHead}>Qty Ordered</div>
                          <div className={styles.nameSecSubHead}>
                            {this.state.totalExpQty}
                          </div>
                        </span>

                        <span className={styles.nameQtyNotShipped}>
                          <div className={styles.nameSecHead}>
                            Ship Qty Adjust +/-
                          </div>
                          <div className={styles.nameSecSubHeadNegative}>
                            {this.state.totalExpShipped}
                          </div>
                        </span>
                      </span>
                    </div>

                    <div className={styles.orderDetailsBottomCloseDiv}>
                      <span className={styles.routeBottomHeading}></span>
                      <span>
                        <span className={styles.nameSecFirst}>
                          <span className={styles.routeBottomClose}>
                            <ReactToPrint
                              trigger={() => {
                                return (
                                  <div>
                                    <img
                                      src={printImg.default}
                                      alt=""
                                      className={styles.imageStyleBackButton}
                                    />
                                    Print
                                  </div>
                                );
                              }}
                              content={() => this.componentExeptionRef}
                            />
                            <div style={{ display: "none" }}>
                              <OrderExceptionToPrint
                                ref={(el) => (this.componentExeptionRef = el)}
                                data={this.state.exceptionDataListPrint}
                              />
                            </div>
                          </span>
                        </span>
                        <span className={styles.nameSecSecond}>
                          <span className={styles.routeBottomClose}>
                            <img
                              src={close.default}
                              alt=""
                              className={styles.imageStyleBackButton}
                              onClick={() => this.backStoreDetail()}
                            />
                            Close
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderDetailList = (value, index) => {
    try {
      return (
        <tr
          className={
            value.latitude !== "0.00" && value.longitude !== "0.00"
              ? styles.markerRowCursor
              : ""
          }
          key={value.stopID}
          {...(value.latitude !== "0.00" &&
            value.longitude !== "0.00" && {
              onClick: () =>
                this.handleStopInfoClick(
                  index,
                  value.latitude,
                  value.longitude
                ),
            })}
        >
          <td style={{ width: "20%" }} className={styles.routeTD}>
            <div
              className={
                value.mode === 0
                  ? "detailBoxDetailStopFirstChildWithRelayTrip"
                  : "detailBoxDetailStopFirstChild"
              }
            >
              {index}
            </div>
          </td>
          <td
            style={{ width: "35%" }}
            className={styles.routeTD}
            id={`markerRowA${index}`}
          >
            <div>
              <div
                className={
                  value.mode === 0
                    ? styles.detailBoxDetailStoreWithRelayTrip
                    : styles.detailBoxDetailStore
                }
              >
                {value.storeID}
              </div>
              <div
                className={
                  value.mode === 0
                    ? styles.detailBoxDetailStoreInfoWithRelayTrip
                    : styles.detailBoxDetailStoreInfo
                }
              >
                {value.address ? value.address : "No Store Address"}
              </div>
              <div
                className={
                  value.mode === 0
                    ? styles.detailBoxDetailStoreInfoWithRelayTrip
                    : styles.detailBoxDetailStoreInfo
                }
              >
                {value.storePhone ? value.storePhone : "No Store Phone #"}
              </div>
            </div>
          </td>
          <td
            style={{ width: "45%" }}
            className={styles.routeTD}
            id={`markerRowB${index}`}
          >
            <div>
              <div
                className={value.mode === 0 ? "statusWithRelayTrip" : "status"}
              >
                {value.status}
              </div>
              <div className={styles.detailBoxTimeSectionWrap}>
                <div
                  className={
                    value.mode === 0
                      ? styles.detailBoxDetailDateTitleSectionWithRelayTrip
                      : styles.detailBoxDetailDateTitleSection
                  }
                >
                  Est Arr&nbsp;&nbsp;&nbsp;
                </div>
                <div
                  className={
                    value.mode === 0
                      ? styles.detailBoxDetailDateSectionWithRelayTrip
                      : styles.detailBoxDetailDateSection
                  }
                >
                  {index === 0 && "-"}

                  {index !== 0 &&
                  moment(value.estimatedArrival).format("lll") ===
                    "Invalid date"
                    ? "No Info"
                    : index === 0
                    ? null
                    : moment(value.estimatedArrival).format("lll")}
                </div>
              </div>
              <div className={styles.detailBoxTimeSectionWrap}>
                <div
                  className={
                    value.mode === 0
                      ? styles.detailBoxDetailDateTitleSectionWithRelayTrip
                      : styles.detailBoxDetailDateTitleSection
                  }
                >
                  Est Dep&nbsp;&nbsp;&nbsp;
                </div>
                <div
                  className={
                    value.mode === 0
                      ? styles.detailBoxDetailDateSectionWithRelayTrip
                      : styles.detailBoxDetailDateSection
                  }
                >
                  {index === last && "-"}
                  {index !== last &&
                  moment(value.estimatedDeparture).format("lll") ===
                    "Invalid date"
                    ? "No Info"
                    : index === last
                    ? null
                    : moment(value.estimatedDeparture).format("lll")}
                </div>
              </div>
              <br />
              <div>
                <div className={styles.detailBoxTimeSectionWrap}>
                  <div
                    className={
                      value.mode === 0
                        ? styles.detailBoxDetailDateTitleSectionWithRelayTrip
                        : styles.detailBoxDetailDateTitleSection
                    }
                  >
                    Act Arr&nbsp;&nbsp;&nbsp;
                  </div>
                  <div
                    className={
                      value.mode === 0
                        ? styles.detailBoxDetailDateSectionWithRelayTrip
                        : styles.detailBoxDetailDateSection
                    }
                  >
                    {index === 0 && "-"}
                    {index !== 0 &&
                    moment(value.actualArrivalTime).format("lll") ===
                      "Invalid date"
                      ? "No Info"
                      : index === 0
                      ? null
                      : moment(value.actualArrivalTime).format("lll")}
                  </div>
                </div>
                <div className={styles.detailBoxTimeSectionWrap}>
                  <div
                    className={
                      value.mode === 0
                        ? styles.detailBoxDetailDateTitleSectionWithRelayTrip
                        : styles.detailBoxDetailDateTitleSection
                    }
                  >
                    Act Dep&nbsp;&nbsp;&nbsp;{" "}
                  </div>
                  <div
                    className={
                      value.mode === 0
                        ? styles.detailBoxDetailDateSectionWithRelayTrip
                        : styles.detailBoxDetailDateSection
                    }
                  >
                    {index === last && "-"}
                    {index !== last &&
                    moment(value.actualDepartureTime).format("lll") ===
                      "Invalid date"
                      ? "No Info"
                      : index === last
                      ? null
                      : moment(value.actualDepartureTime).format("lll")}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
    } catch (err) {}
  };

  renderOrderDetailList = (value) => {
    try {
      return (
        <tr key={value.invNbr}>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.invoiceNumber === "0" ? " " : value.invoiceNumber}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.orderOn}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.corpItemCode}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.upcItem}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.newProduct}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.itemID}
          </td>
          <td
            className="routeTD"
            style={{
              textAlign: "left",
              width: "35%",
              padding: "8px 10px 8px",
            }}
          >
            {value.itemDesc}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.quantityOrdered ? Math.trunc(value.quantityOrdered) : 0}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.shippedQuantity ? Math.trunc(value.shippedQuantity) : 0}
          </td>
        </tr>
      );
    } catch (err) {}
  };

  renderOrderExceptionList = (value) => {
    try {
      return (
        <tr
          key={value.invNbr}
          style={{
            borderTop: value.orderedCICLines === 1 ? "2px solid black" : "",
          }}
        >
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.invoiceNumber === "0" ? " " : value.invoiceNumber}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.orderOn}
          </td>

          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.sub1TypCD !== null && value.sub1TypCD !== " "
              ? "*" + value.corpItemCode
              : value.corpItemCode}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.upcItem}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.newProduct}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.itemID}
          </td>
          <td
            style={{
              textAlign: "left",
              width: "25%",
              padding: "8px 10px 8px",
            }}
          >
            {value.itemDesc}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.quantityOrdered ? Math.trunc(value.quantityOrdered) : 0}
          </td>
          <td className="routeTD" style={{ padding: "8px 10px 8px" }}>
            {value.quantityNotShipped
              ? Math.trunc(value.quantityNotShipped)
              : 0}
          </td>
          <td
            className="routeTD"
            style={{
              textAlign: "left",
              width: "25%",
              padding: "8px 10px 8px",
            }}
          >
            {value.discrepencyReason && value.altCorpItemCode > 0
              ? value.discrepencyReason + ":" + value.altCorpItemCode
              : value.discrepencyReason}
          </td>
        </tr>
      );
    } catch (err) {}
  };

  renderMapList = (value, index) => {
    try {
      return (
        <Marker
          key={index}
          title={
            value.storeId +
            ",Store Phone : " +
            value.storePhone +
            ", Actual Arrival Time : " +
            (moment(value.actualArrivalTimeLabel).format("lll") ===
            "Invalid date"
              ? "No Info"
              : moment(value.actualArrivalTimeLabel).format("lll")) +
            ", Actual Departure Time :" +
            (moment(value.actualDepartureTimeLabel).format("lll") ===
            "Invalid date"
              ? "No Info"
              : moment(value.actualDepartureTimeLabel).format("lll"))
          }
          label={{
            text: value.stopId,
            color: "black",
          }}
          icon={
            value.lat == this.state.latitude &&
            value.lng == this.state.longitude
              ? {
                  path: "M3.65836 5.3694C1.5499 7.60762 0.229244 10.8681 0.280525 13.9349C0.38761 20.3436 3.29186 22.7696 7.93629 31.59C9.6093 35.4713 11.3554 39.5782 13.0161 46.3802C13.2469 47.3835 13.472 48.3155 13.5761 48.3963C13.6802 48.4771 13.9053 47.5418 14.1361 46.5384C15.7968 39.7365 17.543 35.6328 19.216 31.7515C23.8604 22.9311 26.7646 20.5051 26.8717 14.0963C26.923 11.0295 25.5991 7.76582 23.4906 5.5276C21.0821 2.97081 17.4492 1.07859 13.5761 0.999466C9.70305 0.92029 6.06692 2.81261 3.65836 5.3694Z",
                  fillColor: "#00529F",
                  fillOpacity: 1.0,
                  strokeColor: "#00529F",
                  strokeWeight: 2,
                  labelOrigin: new window.google.maps.Point(14, 16),
                  anchor: new window.google.maps.Point(10, 50),
                }
              : {
                  path: "M3.65836 5.3694C1.5499 7.60762 0.229244 10.8681 0.280525 13.9349C0.38761 20.3436 3.29186 22.7696 7.93629 31.59C9.6093 35.4713 11.3554 39.5782 13.0161 46.3802C13.2469 47.3835 13.472 48.3155 13.5761 48.3963C13.6802 48.4771 13.9053 47.5418 14.1361 46.5384C15.7968 39.7365 17.543 35.6328 19.216 31.7515C23.8604 22.9311 26.7646 20.5051 26.8717 14.0963C26.923 11.0295 25.5991 7.76582 23.4906 5.5276C21.0821 2.97081 17.4492 1.07859 13.5761 0.999466C9.70305 0.92029 6.06692 2.81261 3.65836 5.3694Z",
                  fillColor: "#FF4646",
                  strokeColor: "#FF4646",
                  fillOpacity: 1.0,
                  strokeWeight: 2,
                  labelOrigin: new window.google.maps.Point(14, 16),
                  anchor: new window.google.maps.Point(10, 50),
                }
          }
          position={{ lat: value.lat, lng: value.lng }}
        />
      );
    } catch (err) {}
  };
}

export const LoadingContainer = () => {
  return (
    <div>
      <div className={styles.loadingDiv}>
        <div className="loader" />
        <div className={styles.loadingMsg}>Loading...</div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBAekqUBk8V_zOf9oR6pV1ffQG0lkW1bLc",
  LoadingContainer: LoadingContainer,
})(RouteInfoDetailPage);
