import config from "../configurations/config";
import { ValidationError } from "./ValidationError";

export const FetchWrapperPost= async (url, requestMethod,body) => {
      try {
        const header = {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": config.apimSubscriptionKey,
        };
  
        return fetch(config.hostURL + url, {
          method: requestMethod,
          headers: header,
          body:body,
          credentials: "include",
        })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else if (response.status === 401 || response.status === 403) {
            sessionStorage.clear();
            sessionStorage.clear();
            window.location.replace(config.authnBaseURL + "/authenticate");
          } else if (response.status === 404) {
            console.log("Response code 404");
          } else {
            console.log("Response status  out of check");
          }
        })
        .catch((error) => {
          console.log("Error details : " + JSON.stringify(error));
          //throw new Error();
        });
    } catch (error) {
      if (error instanceof ValidationError) {
        sessionStorage.clear();
        sessionStorage.clear();
        window.location.replace(config.authnBaseURL + "/authenticate");
      }
      throw new Error(error);
    }
  };
  